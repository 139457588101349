import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./Pages/Login/Login.jsx"; 
import Dashboard from "./Pages/Dashboard/Dashboard.jsx"
import ThreeCanvas from './Pages/Canvas/Canvas.jsx';
// import './App.css';
import ProtectedRoute from "./Components/ProtectedRoute.js";

const BoundsContext = React.createContext();



const App = () => {
    // Simulated authentication state
    const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Navigate to="/canvas" />} />
      <Route path="/canvas" element={<ThreeCanvas />} />
        <Route
          path="/login"
          element={
            <ProtectedRoute isAllowed={isAuthenticated} redirectTo="/">
              <Login />
            </ProtectedRoute>
          }
        />
         <Route
          path="/dashboard"
          element={
            <ProtectedRoute isAllowed={isAuthenticated} redirectTo="/">
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Routes>

    </Router>
  );
};

export default App;