import React from 'react'

const Dashboard = () => {
  return (  
    <>
    <h1 style={{color: 'black'}}>Welcome to the Visual Lab dashboard</h1>
    <div>

    </div>

    </>
  )
}

export default Dashboard;