// src/components/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ isAllowed, redirectTo = "/", children }) => {
  // If not allowed, redirect
  if (!isAllowed) {
    return <Navigate to={redirectTo} />;
  }

  // Otherwise, render the children (protected page)
  return children;
};

export default ProtectedRoute;
