import { Canvas, useThree} from "@react-three/fiber";
import { OrbitControls, useGLTF} from "@react-three/drei";
import { EffectComposer, Bloom, DepthOfField, RenderPass, SSAARenderPass } from "@react-three/postprocessing";
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import * as THREE from "three";
import html2canvas from "html2canvas";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Moveable from "react-moveable";
import React, {
  Component,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react"; // Import everything here

import * as fabric from 'fabric';
import GradientPicker from 'react-best-gradient-color-picker';
import { useSpring, animated } from 'react-spring';
import {TextField, CircularProgress, Button} from "@mui/material";
import OpenAi from "openai";
import FontFaceObserver from 'fontfaceobserver';
import './Canvas.css';
import { debounce } from 'lodash';


const handleInputFocus = (e) => {
  e.target.select();
  };

class ErrorBoundary extends Component {
  constructor(props) {
  super(props);
  this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
  return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
  console.log("Error:", error, errorInfo);
  }

  render() {
  if (this.state.hasError) {
    return <h2>Something went wrong.</h2>;
  }
  return this.props.children;
  }
}

const fontWeights = [  
  { value: 100, label: 'Thin' },
  { value: 200, label: 'Extra Light' },
  { value: 300, label: 'Light' },
  { value: "Normal", label: 'Normal' },
  { value: 500, label: 'Medium' },
  { value: 600, label: 'Semi Bold' },
  { value: 700, label: 'Bold' },
  { value: 800, label: 'Extra Bold' },
  { value: 900, label: 'Black' }
];

const fontFamilies = [
  "Asap", "Barlow", "Bitter", "Chivo", "DM Sans", "Epilogue", "Fira Sans", "Fraunces", "Inter", "Jost", "Kanit", "Libre Franklin", "Montserrat", "MuseoModerno", "Noto Sans", "Noto Serif", "Noto Serif Display", "Overpass", "Poppins", "Raleway", "Roboto Condensed", "Roboto Slab", "Taviraj", "Urbanist", "Work Sans"
];

const Model = ({ url, scale, exposure, lightColor }) => {
  const gltf = useGLTF(url);

  useEffect(() => {
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        // Enable shadows
        child.castShadow = true;
        child.receiveShadow = true;
        const originalGeometry = child.geometry;
        const smoothedGeometry = BufferGeometryUtils.mergeVertices(originalGeometry);
        child.geometry = smoothedGeometry;
  
        // Improve material properties
        if (child.material) {
          const material = child.material;
  
          material.metalness = 0.8; // More metallic surfaces
          material.roughness = 0.2; // Smoother surface reflections
          material.envMapIntensity = 1; // Boost environment map reflections
  
          // Enhance texture sharpness
          if (material.map) {
            material.map.anisotropy = 16;
            material.map.minFilter = THREE.LinearMipmapLinearFilter;
          }
          if (material.normalMap) {
            material.normalMap.anisotropy = 16;
            material.normalMap.minFilter = THREE.LinearMipmapLinearFilter;
          }
          child.frustumCulled = false;
        }
      }
    });
  }, [gltf]);
  

  return (
    <>
      <ambientLight intensity={exposure} color={lightColor} />
      <primitive object={gltf.scene} scale={scale} />
    </>
  );
};

const GLBViewerContent = ({ fileUrl, scale, exposure, lightColor, controlsState, setControlsState }) => {
  const { camera, gl, scene } = useThree();
  const controlsRef = useRef();


  useEffect(() => {
    if (controlsState) {
      camera.position.copy(controlsState.cameraPosition);
      if (controlsRef.current) {
        controlsRef.current.target.copy(controlsState.controlsTarget);
      }
    }
  }, [controlsState, camera]);

  useEffect(() => {
    const updateControlsState = () => {
      if (controlsRef.current) {
        // Ensure controlsRef.current.target gets updated
        setControlsState({
          cameraPosition: camera.position.clone(),
          controlsTarget: controlsRef.current.target.clone(),
        });
      }
    };

    gl.domElement.addEventListener('mouseup', updateControlsState);
    gl.domElement.addEventListener('touchend', updateControlsState);

    return () => {
      gl.domElement.removeEventListener('mouseup', updateControlsState);
      gl.domElement.removeEventListener('touchend', updateControlsState);
    };
  }, [camera, gl, setControlsState]);

  return (
    <>
      <ambientLight intensity={exposure} color={lightColor} /> 
      <hemisphereLight
        skyColor={'#ffffff'}
        groundColor={'#444444'}
        intensity={0.8}
      />
      {/* <directionalLight
        position={[5, 5, 5]}
        intensity={exposure}
        castShadow
        shadow-mapSize-width={8192} // Higher resolution for shadow maps
        shadow-mapSize-height={8192}
        shadow-radius={2} // Soft shadows
        shadow-camera-near={0.5}
        shadow-camera-far={50}
      /> */}
      <spotLight
        position={[10, 10, 10]}
        angle={0.3}
        penumbra={0.5}
        intensity={exposure}
        castShadow
      />
      <pointLight position={[10, 10, 10]} />
      {fileUrl && <Model url={fileUrl} scale={scale} exposure={exposure} lightColor={lightColor} />}
      <OrbitControls
        ref={controlsRef}
        enablePan={false}
        enableRotate={true}
        enableDamping={true} // Smooth movements
        dampingFactor={0.05}
        mouseButtons={{ RIGHT: THREE.MOUSE.ROTATE }}
        // minDistance={0.0001}
        maxDistance={100000}
      />
      <EffectComposer>
        <Bloom intensity={0.8} luminanceThreshold={0.3} luminanceSmoothing={0.7} /> 
        {/* <DepthOfField focusDistance={0.02} focalLength={0.02} bokehScale={2} /> */}
      </EffectComposer>
    </>
  );
};


const ExposureSlider = ({ model, handleExposureChange, showHeading }) => {
  // Limit the filename to a maximum of 20 characters
  const truncatedFileName = model.fileName.length > 12
    ? model.fileName.slice(0, 12) + '...\u00A0'
    : model.fileName + '\u00A0';

  return (
    <div className="exposure-customization">
      {/* Conditionally show heading only for the first model */}
      {showHeading && <h2 style={{color: "white"}}>Exposure</h2>}

      <label>
        {truncatedFileName}:
        {/* Number input to adjust exposure */}
        <input
          className="exposure-input-models"
          type="number"
          min="0"
          max="10"
          step="0.1"
          value={model.exposure}
          onChange={(e) => handleExposureChange(e, model.id)}
          onBlur={(e) => handleExposureChange(e, model.id, true)} // Save on blur
          onFocus={handleInputFocus}
        />
        {/* Slider to adjust exposure */}
        <input
          className="exposure-slider-models"
          type="range"
          min="0"
          max="10"
          step="0.1"
          value={model.exposure}
          onChange={(e) => handleExposureChange(e, model.id)}
          onMouseUp={(e) => handleExposureChange(e, model.id)} // Save on drag end
          style={{ width: "100%", marginBottom: "10px" }}
        />
      </label>
    </div>
  );
};

const ImageExposureSlider = ({ bg, handleImageExposureChange, showHeading }) => {
  // Safely access bg and bg.fileName to avoid undefined errors
  const truncatedFileName = bg?.fileName
    ? (bg.fileName.length > 12
        ? bg.fileName.slice(0, 12) + '...\u00A0' // Add non-breaking space
        : bg.fileName + '\u00A0') // Add non-breaking space even if not truncated
    : bg.name; // Fallback if bg or bg.fileName is undefined

  return (
    <div className="exposure-customization">
      {/* Conditionally show heading only for the first image */}
      {showHeading && <h2 style={{ color: "white" }}>Exposure</h2>}
      <label>
        {truncatedFileName || bg.name}:
        {/* Number input to adjust exposure */}
        <input
          className="exposure-input-models"
          type="number"
          min="0"
          max="10"
          step="0.1"
          value={bg?.exposure || 0} // Default to 0 if bg or exposure is undefined
          onChange={(e) => handleImageExposureChange(e.target.value, bg?.id)}
          onBlur={(e) => handleImageExposureChange(e.target.value, bg?.id, true)} // Save on blur
          style={{ marginRight: "10px", width: "40px" }}
          onFocus={handleInputFocus}
        />
        {/* Slider to adjust exposure */}
        <input
          className="exposure-slider-models"
          type="range"
          min="0"
          max="10"
          step="0.1"
          value={bg?.exposure || 0} // Default to 0 if bg or exposure is undefined
          onChange={(e) => handleImageExposureChange(e.target.value, bg?.id)}
          onMouseUp={(e) => handleImageExposureChange(e.target.value, bg?.id, true)} // Save on drag end
          style={{ width: "100%", marginBottom: "10px" }}
        />
      </label>
    </div>
  );
};

const ColorPickerBar = ({ color, onColorChange}) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [pickerPosition, setPickerPosition] = useState({
    top: 0,
    left: 0,
  });
  const [offset, setOffset] = useState({ offsetX: 0, offsetY: 0 });
  const [pickerDimensions, setPickerDimensions] = useState({ width: 180, height: 180 });

  const pickerRef = useRef(null);
  const dragHandleRef = useRef(null);
  const [initialOffsetX, setInitialOffsetX] = useState(0); // Default 50% width
  const [initialOffsetY, setInitialOffsetY] = useState(0); // Default 40% height


  const updateDimensions = () => {
    if (window.matchMedia("(max-width: 1919px)").matches) {
      setPickerDimensions({ width: 180, height: 180 });
      setInitialOffsetX(0.25); // Set 40% width for smaller screens
      setInitialOffsetY(0.4); // Set 35% height for smaller screens
    } else {
      setPickerDimensions({ width: 250, height: 250 });
      setInitialOffsetX(0.25); // Set 40% width for smaller screens
      setInitialOffsetY(0.4); // Set 35% height for smaller screens
    }
  };

  useEffect(() => {
    // Set initial dimensions based on current screen size
    updateDimensions();
    
    // Add a resize event listener
    window.addEventListener("resize", updateDimensions);
    
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const calculatePositionFromPercentage = (xPercentage, yPercentage) => {
    const xPos = window.innerWidth * xPercentage;
    const yPos = window.innerHeight * yPercentage;
    return { top: yPos, left: xPos };
  };

  const handleMouseDown = (e) => {
    if (dragHandleRef.current && dragHandleRef.current.contains(e.target)) {
      setDragging(true);
      setOffset({
        offsetX: e.clientX - pickerPosition.left,
        offsetY: e.clientY - pickerPosition.top,
      });
      document.body.style.userSelect = "none";
    }
  };

  const handleMouseMove = (e) => {
    if (dragging && pickerRef.current) {
      const newLeft = e.clientX - offset.offsetX;
      const newTop = e.clientY - offset.offsetY;

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const pickerWidth = pickerRef.current.offsetWidth;
      const pickerHeight = pickerRef.current.offsetHeight;

      const bottomBuffer = windowHeight * 0.001;
      const rightBuffer = windowWidth * 0.001;

      const clampedLeft = Math.min(Math.max(0, newLeft), windowWidth - pickerWidth - rightBuffer);
      const clampedTop = Math.min(Math.max(0, newTop), windowHeight - pickerHeight - bottomBuffer);

      setPickerPosition({
        left: clampedLeft,
        top: clampedTop,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    document.body.style.userSelect = "";
    onColorChange(color, true); 
  };

  const handleBlur = () => {
    onColorChange(color, true); // Save the final color on blur
  };

  

  useEffect(() => {
    if (dragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  const togglePicker = () => {
    setIsPickerVisible((prev) => {
      if (!prev) {
        const { top, left } = calculatePositionFromPercentage(initialOffsetX, initialOffsetY);
        setPickerPosition({ top, left });
      }
      return !prev;
    });
  };



  
  return (
    <div className="color-picker-toggle-container">
      <div className="color-picker-toggle" onClick={togglePicker}>
        <div
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: color,
            marginRight: "10px",
            borderRadius: "50%",
          }}
        />
        <span>{color}</span>
      </div>

      {isPickerVisible && (
        <div
          ref={pickerRef}
          className="color-picker-background"
          style={{
            top: `${pickerPosition.top}px`,
            left: `${pickerPosition.left}px`,
          }}
          onMouseUp={handleMouseUp} 
          onBlur={handleBlur}
        >
          <div
            ref={dragHandleRef}
            onMouseDown={handleMouseDown}
            className="color-picker-drag"
            style={{
              width: "100%",
              backgroundColor: "#383838",
              cursor: "move",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              marginBottom: "10px",
            }}
          />
          <GradientPicker
            value={color}
            onChange={onColorChange}
            width={pickerDimensions.width}
            height={pickerDimensions.height}
            hidePresets
            hideColorTypeBtns

          />
        </div>
      )}
    </div>
  );
};

const ImageAiBar = ({handleAddToCanvas}) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [pickerPosition, setPickerPosition] = useState({
    top: 0,
    left: 0,
  });
  const [offset, setOffset] = useState({ offsetX: 0, offsetY: 0 });
  const [pickerDimensions, setPickerDimensions] = useState({ width: 180, height: 180 });

  const pickerRef = useRef(null);
  const dragHandleRef = useRef(null);
  const [initialOffsetX, setInitialOffsetX] = useState(0); 
  const [initialOffsetY, setInitialOffsetY] = useState(0); 

  const [generatedText, setGeneratedText] = useState("");
  const [textPrompt, setTextPrompt] = useState("");

  const [url, setUrl] = useState("");
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const openai = new OpenAi({apiKey:"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", dangerouslyAllowBrowser: true});

  const placeholderImageUrl = "ai-placeholder-img2.png";

  const handleAiClick = async () => {
    setLoading(true);
  
    try {
      const response = await openai.images.generate({
        model: "dall-e-3",
        prompt: prompt,
        n: 1,
        size: "1024x1024",
        quality: "hd",
      });
  
      let imageUrl = response.data[0]?.url;
  
      if (!imageUrl) {
        throw new Error("Invalid image URL from OpenAI");
      }
  
      const proxyUrl = process.env.REACT_APP_PROXY_URL;
      const proxyResponse = await fetch(`${proxyUrl}/fetch-image?url=${encodeURIComponent(imageUrl)}`);
  
      if (!proxyResponse.ok) throw new Error("Failed to fetch image through proxy");
  
      const blob = await proxyResponse.blob();
      const objectUrl = URL.createObjectURL(blob);
      setUrl(objectUrl);
  
    } catch (error) {
      console.error("Error generating or fetching image:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleTextGeneration = async (prompt) => {
    try {
      const proxyUrl = process.env.REACT_APP_PROXY_URL;
      const response = await fetch(`${proxyUrl}/generate-text`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt })
      });
  
      if (!response.ok) throw new Error("Failed to generate text");
  
      const data = await response.json();
      const generatedText = data.text;
      console.log("Generated Text:", generatedText);
  
      // Do something with the generated text, e.g., display it on the UI
      setGeneratedText(generatedText);  // Assume you have a state variable for this
    } catch (error) {
      console.error("Error generating text:", error);
    }
  };
  
  
  

  const updateDimensions = () => {
    if (window.matchMedia("(max-width: 1919px)").matches) {
      setPickerDimensions({ width: "100%", height: "auto" });
      setInitialOffsetX(0.78); // Set 40% width for smaller screens
      setInitialOffsetY(0.02); // Set 35% height for smaller screens
    } else {
      setPickerDimensions({ width: "100%", height: "auto"});
      setInitialOffsetX(0.78); // Set 40% width for smaller screens
      setInitialOffsetY(0.02); // Set 35% height for smaller screens
    }
  };

  useEffect(() => {
    // Set initial dimensions based on current screen size
    updateDimensions();
    
    // Add a resize event listener
    window.addEventListener("resize", updateDimensions);
    
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const calculatePositionFromPercentage = (xPercentage, yPercentage) => {
    const xPos = window.innerWidth * xPercentage;
    const yPos = window.innerHeight * yPercentage;
    return { top: yPos, left: xPos };
  };

  const handleMouseDown = (e) => {
    if (dragHandleRef.current && dragHandleRef.current.contains(e.target)) {
      setDragging(true);
      setOffset({
        offsetX: e.clientX - pickerPosition.left,
        offsetY: e.clientY - pickerPosition.top,
      });
      document.body.style.userSelect = "none";
    }
  };

  const handleMouseMove = (e) => {
    if (dragging && pickerRef.current) {
      const newLeft = e.clientX - offset.offsetX;
      const newTop = e.clientY - offset.offsetY;

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const pickerWidth = pickerRef.current.offsetWidth;
      const pickerHeight = pickerRef.current.offsetHeight;

      const bottomBuffer = windowHeight * 0.001;
      const rightBuffer = windowWidth * 0.001;

      const clampedLeft = Math.min(Math.max(0, newLeft), windowWidth - pickerWidth - rightBuffer);
      const clampedTop = Math.min(Math.max(0, newTop), windowHeight - pickerHeight - bottomBuffer);

      setPickerPosition({
        left: clampedLeft,
        top: clampedTop,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    document.body.style.userSelect = "";
  };

  useEffect(() => {
    if (dragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  const togglePicker = () => {
    setIsPickerVisible((prev) => {
      if (!prev) {
        const { top, left } = calculatePositionFromPercentage(initialOffsetX, initialOffsetY);
        setPickerPosition({ top, left });
      }
      return !prev;
    });
  };

  return (
    <>
    <div className="image-ai-toggle-container">
      <div className="image-ai-toggle" onClick={togglePicker}>
        <img id="image-ai-toggle-img" src="ai-visual-assistant.png" />
      </div>

      {isPickerVisible && (
        <div
          ref={pickerRef}
          className="image-ai-background"
          onClick={(e) => e.stopPropagation()} // Prevent click events inside popup from reaching the toggle
          style={{
            top: `${pickerPosition.top}px`,
            left: `${pickerPosition.left}px`,
          }}
        >
        
      <div className="image-ai-content">
        <img src="ai-visual-assistant.png" id="image-ai-title"></img>
        <hr style={{margin: '0'}}></hr>
        {/* <h2 style={{margin: '0'}}>Generate AI Image</h2> */}
        <input type="text" placeholder="Imagine..." onChange={(event) => setPrompt(event.target.value)} style={{color: "black", backgroundColor: "white", paddingLeft: "5px", borderRadius: '5px'}} ></input>
        <button onClick={handleAiClick} style={{color: "white", backgroundColor: "#383838", borderRadius: '5px', width: "100%" }}>Generate Image</button>
        <img src={url || placeholderImageUrl} style={{width: pickerDimensions.width, height: pickerDimensions.height}}/>
        
        {loading &&
          <CircularProgress sx={{color: '#8729CF', mx: 'auto' }}></CircularProgress>
        }
        <button onClick={() => handleAddToCanvas(url)} disabled={!url} style={{color: "white", backgroundColor: "#383838", borderRadius: '5px', width: "100%"}}>Add to Canvas</button>
        <hr style={{margin: '0'}}></hr>
        {/* <h2 style={{margin: '0'}}>Generate AI Text</h2> */}
        <div className="ai-text-response">
          {generatedText && <p>Visual Assistant: {generatedText}</p>}
        </div>
    <input
      type="text"
      placeholder="Enter a prompt..."
      value={textPrompt}
      onChange={(e) => setTextPrompt(e.target.value)}
      style={{color: "black", backgroundColor: "white", paddingLeft: "5px", borderRadius: '5px'}}
    />

    
    <button style={{color: "white", backgroundColor: "#383838", borderRadius: '5px', width: "100%"}} onClick={() => handleTextGeneration(textPrompt)}>Generate Text</button>

    
   
        </div>
        </div>
      )}
    </div>
    </>
  );
};

const FontPickerBar = ({ fontFamily, onFontChange, fontFamilies}) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [pickerPosition, setPickerPosition] = useState({
    top: 100,
    left: 100,
  });
  const [initialOffsetX, setInitialOffsetX] = useState(0); // 50% as decimal
  const [initialOffsetY, setInitialOffsetY] = useState(0); // 30% as decimal

  const [offset, setOffset] = useState({ offsetX: 0, offsetY: 0 });
  const [searchTerm, setSearchTerm] = useState('');  // State for search input
  const [pickerDimensions, setPickerDimensions] = useState({ width: 180, height: 180 });

  const pickerRef = useRef(null);
  const dragHandleRef = useRef(null);

  const updateDimensions = () => {
   if (window.matchMedia("(max-width: 1919px)").matches) {
      setPickerDimensions({ width: 200, height: 240 });
      setInitialOffsetX(0.25); // Adjust X offset for smaller screens
      setInitialOffsetY(0.05);  // Adjust Y offset for smaller screens
    }
    else {
      setPickerDimensions({ width: 270, height: 310 });
      setInitialOffsetX(0.25); // Adjust X offset for smaller screens
      setInitialOffsetY(0.05);  // Adjust Y offset for smaller screens
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleMouseDown = (e) => {
    if (dragHandleRef.current && dragHandleRef.current.contains(e.target)) {
      setDragging(true);
      setOffset({
        offsetX: e.clientX - pickerPosition.left,
        offsetY: e.clientY - pickerPosition.top,
      });
      document.body.style.userSelect = "none";
    }
  };


  const calculatePositionFromPercentage = (xPercentage, yPercentage) => {
    const xPos = window.innerWidth * xPercentage;
    const yPos = window.innerHeight * yPercentage;
    return { top: yPos, left: xPos };
  };


  const handleMouseMove = (e) => {
    if (dragging && pickerRef.current) {
      const newLeft = e.clientX - offset.offsetX;
      const newTop = e.clientY - offset.offsetY;

      // Clamp the position to stay within screen bounds
      const clampedLeft = Math.max(0, newLeft);
      const clampedTop = Math.max(0, newTop);

      setPickerPosition({
        left: clampedLeft,
        top: clampedTop,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    document.body.style.userSelect = "";
  };

  useEffect(() => {
    if (dragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  const togglePicker = () => {
    setIsPickerVisible((prev) => {
      if (!prev) {
        const { top, left } = calculatePositionFromPercentage(initialOffsetX, initialOffsetY);
        setPickerPosition({ top, left });
      }
      return !prev;
    });
  };

  const handleFontClick = (font) => {
    // Trigger the font change
    onFontChange({ target: { value: font } }, false); // Immediate change
    // Save the change to history
    onFontChange({ target: { value: font } }, true); // Finalize for history
  };


  const filteredFontFamilies = fontFamilies.filter((font) =>
    font.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div onClick={togglePicker} className="font-picker-toggle">
        <span style={{ fontFamily: fontFamily }}>{fontFamily}</span>
      </div>

      {isPickerVisible && (
        <div
          ref={pickerRef}
          style={{
            position: "fixed",
            top: `${pickerPosition.top}px`,
            left: `${pickerPosition.left}px`,
            zIndex: 2,
            padding: "10px",
            backgroundColor: "#252627",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            width: pickerDimensions.width,
            height: pickerDimensions.height,
          }}
        >
          <div
            ref={dragHandleRef}
            onMouseDown={handleMouseDown}
            className="font-picker-drag"
            style={{
              width: "100%",
              backgroundColor: "#383838",
              cursor: "move",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              marginBottom: "10px",
            }}
          />

          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search fonts..."
            style={{
              width: "100%",
              padding: "5px",
              marginBottom: "10px",
              borderRadius: "5px",
              border: "1px solid #383838",
            }}
          />

          <div
            className="font-picker-scrollable-list"
            style={{
              overflowY: "auto",
              backgroundColor: "#383838",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            {filteredFontFamilies.map((font) => (
              <div
                key={font}
                onClick={() => handleFontClick(font)}
                style={{
                  padding: "5px 10px",
                  cursor: "pointer",
                  fontFamily: font,
                  backgroundColor: font === fontFamily ? "#4a4a4a" : "transparent",
                  borderRadius: "5px",
                  color: "#fff",
                }}
              >
                {font}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};



const LayerPanel = ({
  layers,
  onSelectLayer,
  onMoveLayer,
  onRenameLayer,
  onToggleVisibility,
}) => {
  const [, drop] = useDrop({
    accept: "layer",
    drop: (item, monitor) => {
      // Trigger final save to history on drop
      const didDrop = monitor.didDrop();
      if (!didDrop && typeof onMoveLayer === "function") {
        onMoveLayer(item.index, item.index, true); // Save to history
      }
    },
  });

  return (
  <div ref={drop} className="layers-panel">
    {layers.map((layer, index) => (
    <LayerItem
      key={layer.id}
      index={index}
      layer={layer}
      onSelectLayer={onSelectLayer}
      onMoveLayer={onMoveLayer}
      onRenameLayer={onRenameLayer}
      onToggleVisibility={onToggleVisibility}
    />
    ))}
  </div>
  );
};



const LayerItem = ({
  layer,
  index,
  onSelectLayer,
  onMoveLayer,
  onRenameLayer,
  onToggleVisibility,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [newName, setNewName] = useState(layer.fileName || layer.name);
  
    // Sync `newName` with `layer.fileName` or `layer.name` on prop change
    useEffect(() => {
      setNewName(layer.fileName || layer.name);
    }, [layer.fileName, layer.name]);

      // Sync local isVisible state with global layer.isVisible
  useEffect(() => {
    setIsVisible(layer.isVisible);
  }, [layer.isVisible]);


    // Ref to store the last reorder time
    const lastMoveTimeRef = useRef(0);
    const MOVE_DELAY = 100; // Delay in milliseconds

  // Track drag state and offset position for custom preview
  const [{ isDragging, offset }, drag, preview] = useDrag({
    type: 'layer',
    item: { index, layer },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      offset: monitor.getClientOffset() || { x: 0, y: 0 },
    }),
  });

  const [, drop] = useDrop({
    accept: "layer",
    hover: (item) => {
      const now = Date.now();
  
      // Only reorder if sufficient time has passed since the last reorder
      if (item.index !== index && now - lastMoveTimeRef.current > MOVE_DELAY) {
        onMoveLayer(item.index, index, false); // Pass `false` to prevent saving history
        item.index = index;              // Update dragged item index
        lastMoveTimeRef.current = now;   // Update the last move time
      }
    },
    drop: (item) => {
      // Finalize reordering and save to history
      onMoveLayer(item.index, index, true); // Pass `true` to save history
    },
  });
  

  const handleVisibilityToggle = (e) => {
    e.stopPropagation();
    const newVisibility = !isVisible;
    setIsVisible(newVisibility);
    onToggleVisibility(layer.id, newVisibility); // Update global state
  };
  
  const handleRename = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onRenameLayer(layer.id, newName);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      onRenameLayer(layer.id, newName);
    }
  };

  const truncateName = (name) => {
    return name.length > 12 ? name.slice(0, 12) + '...' : name;
  };

  // Custom Drag Preview Component
const LayerDragPreview = ({ layer, offset }) => {

  const springProps = useSpring({
    transform: `scale(1.05)`,
    opacity: 0.9,
    config: { tension: 250, friction: 20 },
  });

  return (
    <animated.div
      style={{
        ...springProps,
        position: 'absolute',
        top: offset.y,
        left: offset.x,
        pointerEvents: 'none',
        padding: '5px',
        background: '#fff',
        boxShadow: '0px 5px 15px rgba(0,0,0,0.2)',
        borderRadius: '4px',
      }}
    >
      {truncateName(newName)}
    </animated.div>
  );
};

  return (
    <>
      {/* Render the custom drag preview only while dragging */}
      {isDragging && <LayerDragPreview layer={layer} offset={offset} />}

      {/* Main LayerItem structure with existing functionalities */}
      <div
        ref={(node) => drag(drop(node))}
        className="layer-item"
        onClick={() => !isEditing && onSelectLayer(layer.id)}
        style={{
          opacity: isVisible ? 1 : 0.5,
          visibility: isDragging ? 'hidden' : 'visible', // Hide original during drag
        }}
      >
        <div className="layer-item-name">
          {isEditing ? (
            <input
              type="text"
              value={newName}
              onChange={handleNameChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              autoFocus
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <span>{truncateName(newName)}</span>
          )}
        </div>
        <div className="layer-item-buttons-container" >
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleRename(e);
            }}
          >
            <img src="Pencil-Icon-White.png" alt="Edit Layer Name"/>
          </button>
          <button

            onClick={handleVisibilityToggle}
          >
            <img
              src={isVisible ? 'Eye-Icon-ON.png' : 'Eye-Icon-OFF.png'}
              alt="Toggle Visibility"
            />
          </button>
        </div>
      </div>
    </>
  );
};



const getHighestZIndex = (layers) => {
  return layers.reduce((max, layer) => Math.max(max, layer.zIndex || 0), 0);
};

// Reorder layers based on zIndex when rendering
const reorderLayersByZIndex = (layers) => {
  return layers.slice().sort((a, b) => a.zIndex - b.zIndex);
};

const reorderZIndex = (items, orderedLayers) => {
  return items.map((item) => {
  const layerIndex = orderedLayers.findIndex((layer) => layer.id === item.id);
  return { ...item, zIndex: orderedLayers.length - layerIndex };
  });
};

const MoveableContainer = React.forwardRef(({
    position,
    size,
    rotation,
    onUpdate,
    onClick,
    onDoubleClick,
    children,
    isSelected,
    zIndex,
    verticalGuidelines = [],
    horizontalGuidelines = [],
    elementGuidelines = [],
    isTextbox = false,
    moveBy = { x: 0, y: 0 },  // New prop to move the shape
    resizeBy = { width: 0, height: 0 }, 
    rotateBy = 0, // Add rotateBy
    flip = { scaleX: 1, scaleY: 1 }, // New flip prop
    onTransformationChange,
    onArrowKeyNudge,
    onBlur, // Add onBlur prop
    selectedContainer, // Add selectedContainer prop
  },
  ref,
  ) => {
  const targetRef = useRef(null);
  const rafRef = useRef(null);
  const moveableRef = useRef(null); // Reference for Moveable
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false); // State to track Shift key
  // State for transformations, including scale
  const [state, setState] = useState({
    position: position,
    size: { width: size.width, height: size.height },
    rotation: rotation,
    scale: [1, 1],
    keepRatio: false,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isOutsideBounds, setIsOutsideBounds] = useState(false);
  const canvasAreaRef = useRef(document.querySelector(".three-canvas-area"));

  useEffect(() => {
    canvasAreaRef.current = document.querySelector(".three-canvas-area");
  }, []);
   

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Shift') {
        setShiftKeyPressed(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Shift') {
        setShiftKeyPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

    // Function to check if the moveable container is outside the bounds of the canvas
    const checkIfOutsideBounds = useCallback(() => {
      const targetBounds = targetRef.current?.getBoundingClientRect();
      const canvasBounds = canvasAreaRef.current?.getBoundingClientRect();

      if (targetBounds && canvasBounds) {
        const isCompletelyOutside =
          targetBounds.right < canvasBounds.left + 10|| // completely past the left
          targetBounds.left > canvasBounds.right - 10|| // completely past the right
          targetBounds.bottom < canvasBounds.top + 10 || // completely above
          targetBounds.top > canvasBounds.bottom - 10;   // completely below

        setIsOutsideBounds(isCompletelyOutside);
      }
    }, []);

      // Listen for position or resize changes and recheck the bounds
      useEffect(() => {
        // console.log("MoveableContainer: state.position updated to:", state.position);
        checkIfOutsideBounds();
      }, [state.position, state.size, checkIfOutsideBounds]);


      useEffect(() => {
        // Apply rotation if rotateBy changes
        if (rotateBy !== 0) {
          const newRotation = state.rotation + rotateBy;
  
          setState((prevState) => ({
            ...prevState,
            rotation: newRotation,
          }));
  
          // Notify parent about the new rotation
          onUpdate({ rotation: newRotation });
  
          // Reset rotateBy after it is applied
         setState((prevState) => ({
              ...prevState,
              rotation: prevState.rotation,
            }));
         
        }
      }, [rotateBy]);

    const resizeApplied = useRef(false); // Track if resize has been applied

   // Handle resizing
   useEffect(() => {
    if (!resizeApplied.current && (resizeBy.width !== 0 || resizeBy.height !== 0)) {
      // Calculate the new size
      const newSize = {
        width: state.size.width + resizeBy.width,
        height: state.size.height + resizeBy.height,
      };

      // Update the size in state
      setState((prevState) => ({
        ...prevState,
        size: newSize,
      }));

       // Check if the current element has a fabric canvas and if it's a textbox
    const fabricCanvas = targetRef.current.querySelector('canvas')?.fabricCanvas;
    if (fabricCanvas) {
    const fabricTextBox = fabricCanvas.getObjects('textbox')[0]; // Get the textbox object
    if (fabricTextBox) {
      // Resize the fabric textbox
      fabricTextBox.set({
      width: newSize.width,
      height: newSize.height,
      });
      fabricCanvas.setWidth(newSize.width);
      fabricCanvas.setHeight(newSize.height);
      fabricCanvas.renderAll();
    }
    }
      // Notify the parent about the new size
      onUpdate({ size: newSize });

      // Mark resize as applied
      resizeApplied.current = true;
    }
  }, [resizeBy, state.size, onUpdate]);

  // Reset the flag when resizeBy is reset
  useEffect(() => {
    if (resizeBy.width === 0 && resizeBy.height === 0) {
      resizeApplied.current = false; // Allow resizing again
    }
  }, [resizeBy]);

  // Apply transformations directly to DOM without state updates during interaction
  const applyTransformDirectly = (position, size, rotation) => {
    if (targetRef.current) {
    const transformOriginX = size.width / 2;
    const transformOriginY = size.height / 2;
    targetRef.current.style.transformOrigin = `${transformOriginX}px ${transformOriginY}px`;
    targetRef.current.style.transform = `
      translate(${position.x}px, ${position.y}px)
      rotate(${rotation}deg)
      scale(1, 1)
    `;
    targetRef.current.style.width = `${size.width}px`;
    targetRef.current.style.height = `${size.height}px`;
  
    const threeJSElement = targetRef.current.querySelector("canvas");
    synchronizeBoundingBoxWithThreeJS(targetRef.current, threeJSElement, rotation, size, position);
    }
  };

   // RequestAnimationFrame wrapper to apply transforms smoothly
  const scheduleUpdate = useCallback(() => {
    if (rafRef.current) {
    cancelAnimationFrame(rafRef.current);
    }
    rafRef.current = requestAnimationFrame(() => {
    const { position, size, rotation, scale } = state;
    applyTransformDirectly(position, size, rotation, scale);
    });
  }, [state]);

  // Sync DOM with state on change
  useEffect(() => {
    scheduleUpdate();
  }, [scheduleUpdate]);

  // Notify parent on state change (only when interactions end)
  useEffect(() => {
    if (onUpdate) {
    onUpdate(state);
    }
  }, [state, onUpdate]);

  
  useEffect(() => {
    const handleArrowKeyPress = (e) => {
      if (!isSelected) return;
  
      const moveDistance = e.shiftKey ? 10 : 1;
      let newPosition = { ...position }; // Assume position is a prop passed to the container
  
      switch (e.key) {
        case "ArrowUp":
          newPosition.y -= moveDistance;
          break;
        case "ArrowDown":
          newPosition.y += moveDistance;
          break;
        case "ArrowLeft":
          newPosition.x -= moveDistance;
          break;
        case "ArrowRight":
          newPosition.x += moveDistance;
          break;
        default:
          return;
      }
  
      // Emit the new position to the parent via the prop
      onArrowKeyNudge(newPosition); // Calls updateObjectAndLayerPositions in the parent
       // Update the MoveableContainer position directly
    applyTransformDirectly(newPosition, state.size, state.rotation);

    // Update state and notify parent
    setState((prevState) => ({ ...prevState, position: newPosition }));
    onUpdate({ position: newPosition });

    };
  
    window.addEventListener("keydown", handleArrowKeyPress);
  
    return () => {
      window.removeEventListener("keydown", handleArrowKeyPress);
    };
  }, [isSelected, position, onArrowKeyNudge]);
  


    // Function to handle double-click and enable text editing
  const handleDoubleClick = () => {
    if (isTextbox) {
      setIsEditing(true); // Enter editing mode
      setTimeout(() => {
        const fabricCanvas = targetRef.current.querySelector("canvas")?.fabricCanvas;
        if (fabricCanvas) {
          const fabricTextBox = fabricCanvas.getObjects('textbox')[0];
          fabricCanvas.setActiveObject(fabricTextBox);
          fabricTextBox.enterEditing();
          fabricCanvas.renderAll();
        }
      }, 0);
    }
  };

     // Function to handle exiting editing mode properly
     const handleBlur = () => {
      if (isTextbox) {
        setIsEditing(false); // Exit editing mode
        const fabricCanvas = targetRef.current.querySelector("canvas")?.fabricCanvas;
        if (fabricCanvas) {
          const fabricTextBox = fabricCanvas.getObjects("textbox")[0];
          fabricTextBox.exitEditing(); // Exit editing mode
          fabricCanvas.discardActiveObject(); // Remove active object
          fabricCanvas.renderAll(); // Re-render the canvas
  
          // Call the onBlur function from the parent to update state and save history
          if (onBlur) {
            onBlur(selectedContainer, fabricTextBox.text);
          }
        }
      }
    };

     // Advanced global click handler to detect clicks outside both the MoveableContainer and Fabric.js text editor
      const handleClickOutside = (event) => {
        const fabricCanvas = targetRef.current.querySelector("canvas")?.fabricCanvas;
        const fabricTextBox = fabricCanvas?.getObjects('textbox')[0];

        // Check if click is outside the MoveableContainer and Fabric.js text editor
        if (
          targetRef.current &&
          !targetRef.current.contains(event.target) && // Click outside MoveableContainer
          fabricTextBox && fabricTextBox.isEditing // Fabric.js text editor is in editing mode
        ) {
          handleBlur(); // Exit editing mode and restore Moveable controls
        }
      };


    
    // Attach a global event listener to handle clicks anywhere in the document
    useEffect(() => {
      if (isEditing) {
        document.addEventListener('mousedown', handleClickOutside); // Listen for clicks
      } else {
        document.removeEventListener('mousedown', handleClickOutside); // Remove listener
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside); // Cleanup listener on unmount
      };
    }, [isEditing]);

    // Reset the cursor when exiting editing mode to ensure the correct pointer is set
    useEffect(() => {
      if (!isEditing) {
        // Reset the cursor to move (or grab) when exiting text editing mode
        if (targetRef.current) {
          targetRef.current.style.cursor = "move"; // or "grab"
        }
      }
    }, [isEditing]);



   // Real-time synchronization during resize and rotation
   const synchronizeBoundingBoxWithThreeJS = (boundingBoxElement, threeJSElement, rotation, size) => {
    if (boundingBoxElement && threeJSElement) {
    const boundingBoxWidth = size.width;
    const boundingBoxHeight = size.height;

    // Set the size of the Three.js canvas to match the bounding box
    threeJSElement.style.width = `${boundingBoxWidth}px`;
    threeJSElement.style.height = `${boundingBoxHeight}px`;

    // Set the transform origin of the Three.js canvas to the center of the bounding box
    const transformOriginX = boundingBoxWidth / 2;
    const transformOriginY = boundingBoxHeight / 2;
    threeJSElement.style.transformOrigin = `${transformOriginX}px ${transformOriginY}px`;

    // Handle the canvas scale and rotation reset (so only the 3D model rotates)
    threeJSElement.style.transform = `scale(1, 1)`; // Reset scale on the canvas itself

    // Access the Three.js scene (assuming the first child is the model)
    const threeJSScene = threeJSElement.__threeRenderer?.scene?.children[0];

    if (threeJSScene) {
      // Calculate the rotation in radians
      const rotationInRadians = (rotation * Math.PI) / 180;

      // Apply the calculated rotation to the 3D model
      threeJSScene.rotation.set(0, rotationInRadians, 0);

      // **Important Improvement**: Apply proper scaling to the 3D model
      const canvasWidth = threeJSElement.clientWidth;
      const canvasHeight = threeJSElement.clientHeight;

      // Calculate the scaling factors based on the rotated size
      const scaleX = boundingBoxWidth / canvasWidth;
      const scaleY = boundingBoxHeight / canvasHeight;

      // Set the model's scale relative to the bounding box, adjusting for rotation
      threeJSScene.scale.set(scaleX, scaleY, 1);

      // Ensure Three.js renderer updates immediately
      threeJSElement.__threeRenderer.render(threeJSElement.__threeRenderer.scene, threeJSElement.__threeRenderer.camera);
    }
    }
  };

  useEffect(() => {
    finalizeSync(); // Force sync on mount
  }, []);
  

  // Function to trigger a final sync after interactions
  const finalizeSync = () => {
    const boundingBoxElement = targetRef.current;
    const threeJSElement = boundingBoxElement.querySelector("canvas");

    if (boundingBoxElement && threeJSElement) {
    synchronizeBoundingBoxWithThreeJS(boundingBoxElement, threeJSElement, state.rotation, state.size, state.position);
    }
  };

  // Apply the final sync after resizing ends
  const handleResizeEnd = (newSize) => {
    setTimeout(() => {
      finalizeSync();
    }, 100);
    
  

    onTransformationChange("resize", newSize); // Call parent function
  };

  // Apply the final sync after rotation ends
  const handleRotateEnd = (newRotation) => {
    finalizeSync();

    onTransformationChange("rotate", newRotation); // Call parent function
  };

  // Handle drag-end to trigger final sync immediately when dragging stops
  const handleDragEnd = (newPosition) => {
    finalizeSync();
    onTransformationChange("drag", newPosition); // Call parent function
  };
  

  const moveApplied = useRef(false); // Track if move has been applied

  // Function to trigger updateRect for Moveable
  const updateMoveable = () => {
    if (moveableRef.current) {
      moveableRef.current.updateRect(); // Trigger recalculation for Moveable
    }
  };

  // Track position change and trigger Moveable update after re-render
  useEffect(() => {
    updateMoveable();
  }, [state.position, state.size, state.rotation]);

  useEffect(() => {
    if (!moveApplied.current && (moveBy.x !== 0 || moveBy.y !== 0)) {
      // Move only once if the moveBy values have changed
      const newPosition = {
        x: state.position.x + moveBy.x,
        y: state.position.y + moveBy.y,
      };

      setState((prevState) => ({
        ...prevState,
        position: newPosition,
      }));

      
      onUpdate({ position: newPosition });
      moveApplied.current = true; // Mark move as applied
    }
  }, [moveBy, onUpdate, state.position]);

  // Reset moveApplied when moveBy changes (in case new move is triggered later)
  useEffect(() => {
    if (moveBy.x === 0 && moveBy.y === 0) {
      moveApplied.current = false;
    }
  }, [moveBy]);

  // Handlers to update state and DOM during drag/resize
  const handleDrag = ({ beforeTranslate }) => {
    const newPosition = { x: beforeTranslate[0], y: beforeTranslate[1] };
    applyTransformDirectly(
    newPosition,
    state.size,
    state.rotation,
    state.scale,
    );
    setState((prevState) => ({
    ...prevState,
    position: newPosition,
    }));
  };

  const handleResize = ({ width, height, drag: { beforeTranslate } }) => {
    const newPosition = { x: beforeTranslate[0], y: beforeTranslate[1] };
    const newSize = { width, height };
  
    // Apply the transformation only for objects other than Fabric.js textboxes
    applyTransformDirectly(newPosition, newSize, state.rotation, state.scale);
    setState((prevState) => ({
    ...prevState,
    size: newSize,
    position: newPosition,
    }));
  
    // Check if the current element has a fabric canvas and if it's a textbox
    const fabricCanvas = targetRef.current.querySelector('canvas')?.fabricCanvas;
    if (fabricCanvas) {
    const fabricTextBox = fabricCanvas.getObjects('textbox')[0]; // Get the textbox object
    if (fabricTextBox) {
      // Resize the fabric textbox
      fabricTextBox.set({
      width: newSize.width,
      height: newSize.height,
      });
      fabricCanvas.setWidth(newSize.width);
      fabricCanvas.setHeight(newSize.height);
      fabricCanvas.renderAll();
    }
    }
    
  };
  

  const handleRotate = ({ beforeRotate }) => {
     // Snap rotation to nearest 45 degrees if Shift is held
     if (shiftKeyPressed) {
      beforeRotate = Math.round(beforeRotate / 15) * 15;
    }
    applyTransformDirectly(
    state.position,
    state.size,
    beforeRotate,
    state.scale,
    );
    
    setState((prevState) => ({
    ...prevState,
    rotation: beforeRotate,
    }));
  };


  
  const handleScale = ({ drag: { beforeTranslate }, scale }) => {
    const newPosition = { x: beforeTranslate[0], y: beforeTranslate[1] };
    applyTransformDirectly(newPosition, state.size, state.rotation, scale);
    setState((prevState) => ({
    ...prevState,
    scale,
    position: newPosition,
    }));
  };

  const handleResizeStart = ({ direction }) => {
    const isCornerHandle = direction[0] !== 0 && direction[1] !== 0;
    setState((prevState) => ({
    ...prevState,
    keepRatio: isCornerHandle,
    }));
  };

  return (
    <>
    <div
      className="canvas-container"
      style={{
      width: "100%",
      height: "100%",
      overflow: isOutsideBounds ? "visible" : "hidden", // Toggle overflow
      position: "absolute",
      }}
    >
      <div
      ref={(el) => {
        targetRef.current = el;
        if (ref) ref.current = el;
      }}
      style={{
        position: "absolute",
        transform: `translate(${state.position.x}px, ${state.position.y}px) rotate(${state.rotation}deg)  scale(${state.scale[0]}, ${state.scale[1]})`,
        width: `${state.size.width}px`,
        height: `${state.size.height}px`,
        zIndex: zIndex,
        userSelect: isEditing ? "text" : "none",    // Allow text selection during editing
        cursor: isEditing ? "text" : "move", // Update the cursor based on editing state
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={(e) => {
        if (!isEditing) {
        e.stopPropagation();
        if (onClick) onClick();
        }
      }}
      // onDoubleClick={handleDoubleClick}
      onDoubleClick={handleDoubleClick} // Enable double click to start editing
      onBlur={handleBlur} // Handle blur to stop editing
      >
     <div
            style={{
              transform: `scale(${flip.scaleX}, ${flip.scaleY})`, // Flip only applied to child
              width: `${state.size.width}px`,
              height: `${state.size.height}px`,
              overflow: "hidden",
            }}
          >
            {children}
          </div>
      </div>
    </div>

    {(isSelected || isHovered) && (
      <Moveable
      ref={moveableRef}
      target={targetRef.current}
      draggable={!isEditing}
      resizable={!isEditing}
      rotatable={!isEditing}
      scalable={!isEditing}
      keepRatio={state.keepRatio}
      snappable={true}
      snapThreshold={10}
      snapCenter={true}
      snapHorizontal={true}
      snapVertical={true}
      snapDirections={{
        top: true,
        left: true,
        bottom: true,
        right: true,
        center: true,
        middle: true,
      }}
      elementSnapDirections={{
        top: true,
        left: true,
        bottom: true,
        right: true,
        center: true,
        middle: true,
      }}
      maxSnapElementGuidelineDistance={500}
      elementGuidelines={elementGuidelines}
      verticalGuidelines={verticalGuidelines}
      horizontalGuidelines={horizontalGuidelines}
      onResizeStart={handleResizeStart}
      onResize={handleResize}
      onDrag={handleDrag}
      onRotate={handleRotate}
      onScale={handleScale}
      renderDirections={["nw", "n", "ne", "w", "e", "sw", "s", "se"]}
      onResizeEnd={({ lastEvent: { width, height } }) => handleResizeEnd({ width, height })}
      onRotateEnd={({ lastEvent: { rotation } }) => handleRotateEnd(rotation)}
      onDragEnd={({ lastEvent }) => {
        if (lastEvent) {
          const { left, top } = lastEvent;
          handleDragEnd({ x: left, y: top });
        } else {
          console.warn("onDragEnd triggered without a lastEvent");
        }
      }}
      rotationPosition={"top"}
      throttleDrag={0}
      throttleResize={0}
      throttleRotate={0}
      startDragRotate={0} 
      throttleDragRotate={0}
      />
    )}
    </>
  );
  },
);

const ThreeCanvas = () => {
  const [bgFiles, setBgFiles] = useState([]);
  const [imageButtons, setImageButtons] = useState([
  {
    id: "1",
    name: "Shadow 1",
    thumbnailSrc: "Shadow-Icon-1.png",
    fileSrc: "Shadow-1.png",
  },
  {
    id: "2",
    name: "Shadow 2",
    thumbnailSrc: "Shadow-Icon-2.png",
    fileSrc: "Shadow-2.png",
  },
  {
    id: "3",
    name: "Shadow 3",
    thumbnailSrc: "Shadow-Icon-3.png",
    fileSrc: "Shadow-3.png",
  },
  {
    id: "4",
    name: "Shadow 4",
    thumbnailSrc: "Shadow-Icon-4.png",
    fileSrc: "Shadow-4.png",
  },
  {
    id: "5",
    name: "Shadow 5",
    thumbnailSrc: "Shadow-Icon-5.png",
    fileSrc: "Shadow-5.png",
  },
  {
    id: "6",
    name: "Shadow 6",
    thumbnailSrc: "Shadow-Icon-6.png",
    fileSrc: "Shadow-6.png",
  },
  {
    id: "7",
    name: "Shadow 7",
    thumbnailSrc: "Shadow-Icon-7.png",
    fileSrc: "Shadow-7.png",
  },
  {
    id: "8",
    name: "Shadow 8",
    thumbnailSrc: "Shadow-Icon-8.png",
    fileSrc: "Shadow-8.png",
  },
  {
    id: "9",
    name: "Shadow 9",
    thumbnailSrc: "Shadow-Icon-9.png",
    fileSrc: "Shadow-9.png",
  },
  {
    id: "10",
    name: "Shadow 10",
    thumbnailSrc: "Shadow-Icon-10.png",
    fileSrc: "Shadow-10.png",
  },
  ]);
  const [modelFiles, setModelFiles] = useState([]);
  const [textBoxes, setTextBoxes] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [canvasSize, setCanvasSize] = useState({
  width: "1080px",
  height: "1080px",
  });
  const [canvasBackgroundColor, setCanvasBackgroundColor] = useState({
  r: 255,
  g: 255,
  b: 255,
  a: 1,  // Default opacity to 1 (fully opaque)
  });
  // Default to white

  const [zoom, setZoom] = useState(0.7);
  const [activeSidebar, setActiveSidebar] = useState(1);
  const [fontSize, setFontSize] = useState("25px");
  const [fontFamily, setFontFamily] = useState("Poppins");
  const [shapeColor, setShapeColor] = useState("#CBCBCB");
  const [shapeOpacity, setShapeOpacity] = useState(1);
  const [fontColor, setFontColor] = useState("rgba(0, 0, 0, 1)");
  const [textOpacity, setTextOpacity] = useState(1);
  const [layers, setLayers] = useState([]);

  const [verticalGuidelines, setVerticalGuidelines] = useState([]);
  const [horizontalGuidelines, setHorizontalGuidelines] = useState([]);
  const moveableRefs = useRef([]);

  const canvasRef = useRef();
  const [fileName, setFileName] = useState("File Name"); // File name state
  const [exportFormat, setExportFormat] = useState("png"); // Export format state
  const [transparentBg, setTransparentBg] = useState(false); // Background transparency state
  const bgInputRef = useRef();
  const modelInputRef = useRef();
  const captureDivRef = useRef();
  const canvasScaleRef = useRef(1);

  const sidebarRef1 = useRef();
  const sidebarRef2 = useRef();
  const sidebarRef3 = useRef();
  const sidebarRef4 = useRef();
  const sidebarRef5 = useRef();
  const sidebarRef6 = useRef();

  const [controlsState, setControlsState] = useState(null);

  const [moveBy, setMoveBy] = useState({ x: 0, y: 0 });


  const [resizeBy, setResizeBy] = useState({ width: 0, height: 0 });

  const [rotateBy, setRotateBy] = useState(0);
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false); // State to track Shift key
  const [isLinked, setIsLinked] = useState(false); // State to toggle linking of width and height
  const [selectedFontWeight, setSelectedFontWeight] = useState('400'); // Default font weight

  const [isSliderShifted, setIsSliderShifted] = useState(false);

  const handleTextboxBlur = (id, newText) => {
    // Update the content of the textbox with the given ID
    const updatedTextBoxes = textBoxes.map((box) =>
      box.id === id ? { ...box, content: newText } : box
    );
  
    // Save to history
    updateState({ textBoxes: updatedTextBoxes });
  };
  

  const updateObjectAndLayerPositions = (newPosition) => {
    if (!selectedContainer) return;
  
    // Update the selected object's position across all relevant arrays
    const updatedShapes = shapes.map((shape) =>
      shape.id === selectedContainer
        ? { ...shape, position: newPosition }
        : shape
    );
  
    const updatedBgFiles = bgFiles.map((bg) =>
      bg.id === selectedContainer
        ? { ...bg, position: newPosition }
        : bg
    );
  
    const updatedModelFiles = modelFiles.map((model) =>
      model.id === selectedContainer
        ? { ...model, position: newPosition }
        : model
    );
  
    const updatedTextBoxes = textBoxes.map((textBox) =>
      textBox.id === selectedContainer
        ? { ...textBox, position: newPosition }
        : textBox
    );
  
    const updatedLayers = layers.map((layer) =>
      layer.id === selectedContainer
        ? { ...layer, position: newPosition }
        : layer
    );
  
    // Update the state
    setShapes(updatedShapes);
    setBgFiles(updatedBgFiles);
    setModelFiles(updatedModelFiles);
    setTextBoxes(updatedTextBoxes);
    setLayers(updatedLayers);
  
    // Save the updated state to history
    updateState({
      shapes: updatedShapes,
      bgFiles: updatedBgFiles,
      modelFiles: updatedModelFiles,
      textBoxes: updatedTextBoxes,
      layers: updatedLayers,
    });
  };
  
  


  const handleTransformationChange = (type, updatedProperties) => {

    console.log("onTransformationChange called:", type, updatedProperties);
    if (selectedContainer) {
      // Update positions for shapes
      setShapes((prevShapes) =>
        prevShapes.map((shape) =>
          shape.id === selectedContainer
            ? {
                ...shape,
                position: {
                  x: updatedProperties.x,
                  y: updatedProperties.y
                }
              }
            : shape
        )
      );

      // Update positions for layers
      setLayers((prevLayers) =>
        prevLayers.map((layer) =>
          layer.id === selectedContainer
            ? {
                ...layer,
                position: {
                  x: updatedProperties.x,
                  y: updatedProperties.y
                }
              }
            : layer
        )
      );

      // Update positions for bgFiles (if they have position properties)
      setBgFiles((prevBgFiles) =>
        prevBgFiles.map((bgFile) =>
          bgFile.id === selectedContainer
            ? {
                ...bgFile,
                position: {
                  x: updatedProperties.x,
                  y: updatedProperties.y
                }
              }
            : bgFile
        )
      );

      // Update positions for modelFiles (if they have position properties)
      setModelFiles((prevModelFiles) =>
        prevModelFiles.map((modelFile) =>
          modelFile.id === selectedContainer
            ? {
                ...modelFile,
                position: {
                  x: updatedProperties.x,
                  y: updatedProperties.y
                }
              }
            : modelFile
        )
      );

      // Update positions for textBoxes (if they have position properties)
      setTextBoxes((prevTextBoxes) =>
        prevTextBoxes.map((textBox) =>
          textBox.id === selectedContainer
            ? {
                ...textBox,
                position: {
                  x: updatedProperties.x,
                  y: updatedProperties.y
                }
              }
            : textBox
        )
      );
    }

    // Save the entire state to history after updating all objects
    updateState({
      bgFiles,
      modelFiles,
      textBoxes,
      shapes,
      layers,
    });
  };

  const [history, setHistory] = useState({
    past: [],
    present: {
      bgFiles: [],
      modelFiles: [],
      textBoxes: [],
      shapes: [],
      layers: [],
      canvasBackgroundColor: { r: 255, g: 255, b: 255, a: 1 }, // Default white
    },
    future: []
  });

  // Utility to save current state to history
  const saveHistory = (newState) => {
    console.log("Saving to history:", newState);
    setHistory(prev => ({
      past: [...prev.past, prev.present],
      present: newState,
      future: [] // Clear future on new state
    }));
  };

  // Update component states when present state in history changes
  useEffect(() => {
    const { bgFiles, modelFiles, textBoxes, shapes, layers, canvasBackgroundColor } = history.present;
    setBgFiles(bgFiles);
    setModelFiles(modelFiles);
    setTextBoxes(textBoxes);
    setShapes(shapes);
    setLayers(layers);
    setCanvasBackgroundColor(canvasBackgroundColor);
  }, [history.present]);

  // Wrapper to update states and save to history
  const updateState = (updatedState) => {
    // Save shapes and layers to history together
    saveHistory({
      shapes: updatedState.shapes || shapes,
      layers: updatedState.layers || layers,
      bgFiles: updatedState.bgFiles || bgFiles, 
      modelFiles: updatedState.modelFiles || modelFiles, 
      textBoxes: updatedState.textBoxes || textBoxes, 
      canvasBackgroundColor: updatedState.canvasBackgroundColor || canvasBackgroundColor,
    });
  };
  
  const [canvasKey, setCanvasKey] = useState(Date.now());

  const handleUndo = () => {
    setHistory((prev) => {
      if (prev.past.length === 0) return prev;

      const newPresent = prev.past[prev.past.length - 1];
      const newPast = prev.past.slice(0, -1);

      // Update shapes and layers
      setShapes(newPresent.shapes);
      setLayers(newPresent.layers);
      setBgFiles(newPresent.bgFiles);
      setModelFiles(newPresent.modelFiles);
      setTextBoxes(newPresent.textBoxes);

  // Force a canvas re-render by updating the canvas key
      setCanvasKey(Date.now());
  
      return {
        past: newPast,
        present: newPresent,
        future: [prev.present, ...prev.future],
      };
    });
  };
  
  const handleRedo = () => {
    setHistory((prev) => {
      if (prev.future.length === 0) return prev;
  
      const newPresent = prev.future[0];
      const newFuture = prev.future.slice(1);
  
      // Update shapes and layers
      setShapes(newPresent.shapes);
      setLayers(newPresent.layers);
      setBgFiles(newPresent.bgFiles);
      setModelFiles(newPresent.modelFiles);
      setTextBoxes(newPresent.textBoxes);
  
      // Force a canvas re-render by updating the canvas key
      setCanvasKey(Date.now());


  
      return {
        past: [...prev.past, prev.present],
        present: newPresent,
        future: newFuture,
      };
    });
  };
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the active element is an input field, textarea, or content-editable
      const activeElement = document.activeElement;
      const isEditing = activeElement && 
        (activeElement.tagName === 'INPUT' || 
         activeElement.tagName === 'TEXTAREA' || 
         activeElement.isContentEditable);
  
      if (!isEditing) { // Only handle undo/redo if not editing
        if (event.ctrlKey || event.metaKey) { // Detect Ctrl on Windows/Linux or Command on Mac
          if (event.key === 'z' || event.key === 'Z') {
            event.preventDefault(); // Prevent default undo behavior in the browser
            handleUndo(); // Call the undo function
          } else if (event.key === 'y' || event.key === 'Y') {
            event.preventDefault(); // Prevent default redo behavior in the browser
            handleRedo(); // Call the redo function
          }
        }
      }
    };
  
    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown);
  
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUndo, handleRedo]);
  

  const handleImageAiBarClick = (e) => {
    e.stopPropagation();
    setIsSliderShifted(!isSliderShifted);
  };
  


// Handle linking button click
const toggleLinkDimensions = () => {
  setIsLinked((prev) => !prev);
};

const LINKED_BUTTON_STYLE = {
  active: { backgroundColor: '#8729CF' }, // Style for active link
  inactive: {}        // Style for inactive link
};

const handleHorizontalFlip = () => {
  const updatedBgFiles = bgFiles.map((bg) =>
    bg.id === selectedContainer
      ? { ...bg, flip: { ...bg.flip, scaleX: -bg.flip.scaleX } }
      : bg
  );
  const updatedModelFiles = modelFiles.map((model) =>
    model.id === selectedContainer
      ? { ...model, flip: { ...model.flip, scaleX: -model.flip.scaleX } }
      : model
  );
  const updatedTextBoxes = textBoxes.map((textBox) =>
    textBox.id === selectedContainer
      ? { ...textBox, flip: { ...textBox.flip, scaleX: -textBox.flip.scaleX } }
      : textBox
  );
  const updatedShapes = shapes.map((shape) =>
    shape.id === selectedContainer
      ? { ...shape, flip: { ...shape.flip, scaleX: -shape.flip.scaleX } }
      : shape
  );

  updateState({
    bgFiles: updatedBgFiles,
    modelFiles: updatedModelFiles,
    textBoxes: updatedTextBoxes,
    shapes: updatedShapes,
    layers, // Layers are not updated, so keep the current state
  });
};

const handleVerticalFlip = () => {
  const updatedBgFiles = bgFiles.map((bg) =>
    bg.id === selectedContainer
      ? { ...bg, flip: { ...bg.flip, scaleY: -bg.flip.scaleY } }
      : bg
  );
  const updatedModelFiles = modelFiles.map((model) =>
    model.id === selectedContainer
      ? { ...model, flip: { ...model.flip, scaleY: -model.flip.scaleY } }
      : model
  );
  const updatedTextBoxes = textBoxes.map((textBox) =>
    textBox.id === selectedContainer
      ? { ...textBox, flip: { ...textBox.flip, scaleY: -textBox.flip.scaleY } }
      : textBox
  );
  const updatedShapes = shapes.map((shape) =>
    shape.id === selectedContainer
      ? { ...shape, flip: { ...shape.flip, scaleY: -shape.flip.scaleY } }
      : shape
  );

  updateState({
    bgFiles: updatedBgFiles,
    modelFiles: updatedModelFiles,
    textBoxes: updatedTextBoxes,
    shapes: updatedShapes,
    layers, // Layers are not updated, so keep the current state
  });
};

  

  useEffect(() => {
    // Function to set zoom based on screen size
    const updateZoomBasedOnScreenSize = () => {
      if (window.matchMedia("(max-width: 1439px)").matches) {
        setZoom(0.5); // Zoom level for small screens
      } else if (window.matchMedia("(max-width: 1919px)").matches) {
        setZoom(0.6); // Zoom level for medium screens
      } else {
        setZoom(0.7); // Zoom level for large screens
      }
    };

    // Set initial zoom
    updateZoomBasedOnScreenSize();

    // Listen for screen resize and update zoom accordingly
    window.addEventListener("resize", updateZoomBasedOnScreenSize);
    return () => window.removeEventListener("resize", updateZoomBasedOnScreenSize);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Shift') {
        setShiftKeyPressed(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Shift') {
        setShiftKeyPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleRotateObject = (deltaRotation, saveOnEnd = false) => {
    if (isNaN(deltaRotation) || deltaRotation === '') return;
    const selectedObject = getSelectedObject();
  
    if (selectedObject) {
      setRotateBy(deltaRotation);
  
      // Rotate the selected object in each state list as needed
      const updatedBgFiles = bgFiles.map((bg) =>
        bg.id === selectedContainer ? { ...bg, rotation: bg.rotation + deltaRotation } : bg
      );
      const updatedModelFiles = modelFiles.map((model) =>
        model.id === selectedContainer ? { ...model, rotation: model.rotation + deltaRotation } : model
      );
      const updatedTextBoxes = textBoxes.map((textBox) =>
        textBox.id === selectedContainer ? { ...textBox, rotation: textBox.rotation + deltaRotation } : textBox
      );
      const updatedShapes = shapes.map((shape) =>
        shape.id === selectedContainer ? { ...shape, rotation: shape.rotation + deltaRotation } : shape
      );
  
      // Apply the updates to the states
      setBgFiles(updatedBgFiles);
      setModelFiles(updatedModelFiles);
      setTextBoxes(updatedTextBoxes);
      setShapes(updatedShapes);
  
      // Save the updated state to history only if this is the final change
      if (saveOnEnd) {
        updateState({
          bgFiles: updatedBgFiles,
          modelFiles: updatedModelFiles,
          textBoxes: updatedTextBoxes,
          shapes: updatedShapes,
          layers, // Layers are not updated here, so keep the current state
        });
      }
  
      // Reset `rotateBy` after some time to clear UI rotation indicator if needed
      setTimeout(() => {
        setRotateBy(0);
      }, 200);
    }
  };
  
  

// Function to rotate the selected object by 90 degrees
const rotateBy90Degrees = () => {
  handleRotateObject(90, true); // Increment rotation by 90 degrees
};



const handleResizeObjectWidth = useCallback(
  (deltaWidth, saveOnEnd = false) => {
    if (isNaN(deltaWidth) || deltaWidth === "") return;

    const selectedObject = getSelectedObject();
    if (selectedObject) {
      const newWidth = selectedObject.size.width + deltaWidth;
      const newHeight = isLinked
        ? Math.round((selectedObject.size.height / selectedObject.size.width) * newWidth)
        : selectedObject.size.height;

      // Update resize dimensions
      setResizeBy({
        width: deltaWidth,
        height: isLinked ? newHeight - selectedObject.size.height : 0,
      });

      // Batch updates to avoid redundant re-renders
      const updatedObjects = {
        bgFiles: bgFiles.map((bg) =>
          bg.id === selectedContainer ? { ...bg, size: { width: newWidth, height: newHeight } } : bg
        ),
        modelFiles: modelFiles.map((model) =>
          model.id === selectedContainer ? { ...model, size: { width: newWidth, height: newHeight } } : model
        ),
        textBoxes: textBoxes.map((textBox) =>
          textBox.id === selectedContainer ? { ...textBox, size: { width: newWidth, height: newHeight } } : textBox
        ),
        shapes: shapes.map((shape) =>
          shape.id === selectedContainer ? { ...shape, size: { width: newWidth, height: newHeight } } : shape
        ),
      };

      // Save to history only on blur
      if (saveOnEnd) {
        updateState({ ...updatedObjects, layers });
      }

      setBgFiles(updatedObjects.bgFiles);
      setModelFiles(updatedObjects.modelFiles);
      setTextBoxes(updatedObjects.textBoxes);
      setShapes(updatedObjects.shapes);

      // Reset resizeBy after a slight delay
      requestAnimationFrame(() => {
        setResizeBy({ width: 0, height: 0 });
      });
    }
  },
  [isLinked, selectedContainer, bgFiles, modelFiles, textBoxes, shapes]
);

const handleResizeObjectHeight = useCallback(
  (deltaHeight, saveOnEnd = false) => {
    if (isNaN(deltaHeight) || deltaHeight === "") return;

    const selectedObject = getSelectedObject();
    if (selectedObject) {
      const newHeight = selectedObject.size.height + deltaHeight;
      const newWidth = isLinked
        ? Math.round((selectedObject.size.width / selectedObject.size.height) * newHeight)
        : selectedObject.size.width;

      // Update resize dimensions
      setResizeBy({
        width: isLinked ? newWidth - selectedObject.size.width : 0,
        height: deltaHeight,
      });

      // Batch updates to avoid redundant re-renders
      const updatedObjects = {
        bgFiles: bgFiles.map((bg) =>
          bg.id === selectedContainer ? { ...bg, size: { width: newWidth, height: newHeight } } : bg
        ),
        modelFiles: modelFiles.map((model) =>
          model.id === selectedContainer ? { ...model, size: { width: newWidth, height: newHeight } } : model
        ),
        textBoxes: textBoxes.map((textBox) =>
          textBox.id === selectedContainer ? { ...textBox, size: { width: newWidth, height: newHeight } } : textBox
        ),
        shapes: shapes.map((shape) =>
          shape.id === selectedContainer ? { ...shape, size: { width: newWidth, height: newHeight } } : shape
        ),
      };

      // Save to history only on blur
      if (saveOnEnd) {
        updateState({ ...updatedObjects, layers });
      }

      setBgFiles(updatedObjects.bgFiles);
      setModelFiles(updatedObjects.modelFiles);
      setTextBoxes(updatedObjects.textBoxes);
      setShapes(updatedObjects.shapes);

      // Reset resizeBy after a slight delay
      requestAnimationFrame(() => {
        setResizeBy({ width: 0, height: 0 });
      });
    }
  },
  [isLinked, selectedContainer, bgFiles, modelFiles, textBoxes, shapes]
);

  const getSelectedObject = () => {
    // Logic to get the currently selected object (shape, textBox, etc.)
    return shapes.find(shape => shape.id === selectedContainer) ||
           textBoxes.find(textBox => textBox.id === selectedContainer) ||
           bgFiles.find(bg => bg.id === selectedContainer) ||
           modelFiles.find(model => model.id === selectedContainer);           
  };
 // Handle moving the selected shape, textbox, image, or 3D model to the center X (horizontal center)
 const handleMoveObjectCenterX = () => {
  const object = getSelectedObject();

  if (object) {
    const canvasWidth = parseInt(canvasSize.width, 10);
    const canvasCenterX = canvasWidth / 2;
    const objectCenterX = object.position.x + object.size.width / 2;
    const moveByX = canvasCenterX - objectCenterX;

    if (moveByX !== 0) {
      setMoveBy({ x: moveByX, y: 0 });

      const updatedObjects = {
        bgFiles: bgFiles.map(bg => bg.id === selectedContainer ? { ...bg, position: { ...bg.position, x: bg.position.x + moveByX } } : bg),
        modelFiles: modelFiles.map(model => model.id === selectedContainer ? { ...model, position: { ...model.position, x: model.position.x + moveByX } } : model),
        textBoxes: textBoxes.map(textBox => textBox.id === selectedContainer ? { ...textBox, position: { ...textBox.position, x: textBox.position.x + moveByX } } : textBox),
        shapes: shapes.map(shape => shape.id === selectedContainer ? { ...shape, position: { ...shape.position, x: shape.position.x + moveByX } } : shape),
      };

      updateState(updatedObjects);

      setTimeout(() => setMoveBy({ x: 0, y: 0 }), 200);
    }
  }
};


const handleMoveObjectCenterY = () => {
  const object = getSelectedObject();

  if (object) {
    const canvasHeight = parseInt(canvasSize.height, 10);
    const canvasCenterY = canvasHeight / 2;
    const objectCenterY = object.position.y + object.size.height / 2;
    const moveByY = canvasCenterY - objectCenterY;

    if (moveByY !== 0) {
      setMoveBy({ x: 0, y: moveByY });

      const updatedObjects = {
        bgFiles: bgFiles.map(bg => bg.id === selectedContainer ? { ...bg, position: { ...bg.position, y: bg.position.y + moveByY } } : bg),
        modelFiles: modelFiles.map(model => model.id === selectedContainer ? { ...model, position: { ...model.position, y: model.position.y + moveByY } } : model),
        textBoxes: textBoxes.map(textBox => textBox.id === selectedContainer ? { ...textBox, position: { ...textBox.position, y: textBox.position.y + moveByY } } : textBox),
        shapes: shapes.map(shape => shape.id === selectedContainer ? { ...shape, position: { ...shape.position, y: shape.position.y + moveByY } } : shape),
      };

      updateState(updatedObjects);

      setTimeout(() => setMoveBy({ x: 0, y: 0 }), 200);
    }
  }
};


// Handle moving the selected shape, textbox, image, or 3D model to the right edge
const handleMoveObjectRight = () => {

  const object = getSelectedObject();

  if (object) {
    const canvasWidth = parseInt(canvasSize.width, 10);
    const currentRightEdge = object.position.x + object.size.width;
    const moveByX = canvasWidth - currentRightEdge;

    if (moveByX > 0) {
      setMoveBy({ x: moveByX, y: 0 });

      const updatedObjects = {
        bgFiles: bgFiles.map(bg => bg.id === selectedContainer ? { ...bg, position: { ...bg.position, x: bg.position.x + moveByX } } : bg),
        modelFiles: modelFiles.map(model => model.id === selectedContainer ? { ...model, position: { ...model.position, x: model.position.x + moveByX } } : model),
        textBoxes: textBoxes.map(textBox => textBox.id === selectedContainer ? { ...textBox, position: { ...textBox.position, x: textBox.position.x + moveByX } } : textBox),
        shapes: shapes.map(shape => shape.id === selectedContainer ? { ...shape, position: { ...shape.position, x: shape.position.x + moveByX } } : shape),
      };

      updateState(updatedObjects);

      setTimeout(() => setMoveBy({ x: 0, y: 0 }), 200);
    }
  }
};

// Handle moving the selected shape, textbox, image, or 3D model to the left edge
const handleMoveObjectLeft = () => {
  const object = getSelectedObject();

  if (object) {
    const currentLeftEdge = object.position.x;
    const moveByX = -currentLeftEdge;

    if (moveByX !== 0) {
      setMoveBy({ x: moveByX, y: 0 });

      const updatedObjects = {
        bgFiles: bgFiles.map(bg => bg.id === selectedContainer ? { ...bg, position: { ...bg.position, x: bg.position.x + moveByX } } : bg),
        modelFiles: modelFiles.map(model => model.id === selectedContainer ? { ...model, position: { ...model.position, x: model.position.x + moveByX } } : model),
        textBoxes: textBoxes.map(textBox => textBox.id === selectedContainer ? { ...textBox, position: { ...textBox.position, x: textBox.position.x + moveByX } } : textBox),
        shapes: shapes.map(shape => shape.id === selectedContainer ? { ...shape, position: { ...shape.position, x: shape.position.x + moveByX } } : shape),
      };

      updateState(updatedObjects);

      setTimeout(() => setMoveBy({ x: 0, y: 0 }), 200);
    }
  }
};

// Handle moving the selected shape, textbox, image, or 3D model to the top edge
const handleMoveObjectTop = () => {
  const object = getSelectedObject();

  if (object) {
    const currentTopEdge = object.position.y;
    const moveByY = -currentTopEdge;

    if (moveByY !== 0) {
      setMoveBy({ x: 0, y: moveByY });

      const updatedObjects = {
        bgFiles: bgFiles.map(bg => bg.id === selectedContainer ? { ...bg, position: { ...bg.position, y: bg.position.y + moveByY } } : bg),
        modelFiles: modelFiles.map(model => model.id === selectedContainer ? { ...model, position: { ...model.position, y: model.position.y + moveByY } } : model),
        textBoxes: textBoxes.map(textBox => textBox.id === selectedContainer ? { ...textBox, position: { ...textBox.position, y: textBox.position.y + moveByY } } : textBox),
        shapes: shapes.map(shape => shape.id === selectedContainer ? { ...shape, position: { ...shape.position, y: shape.position.y + moveByY } } : shape),
      };

      updateState(updatedObjects);

      setTimeout(() => setMoveBy({ x: 0, y: 0 }), 200);
    }
  }
};

// Handle moving the selected shape, textbox, image, or 3D model to the bottom edge
const handleMoveObjectBottom = () => {
  const object = getSelectedObject();

  if (object) {
    const canvasHeight = parseInt(canvasSize.height, 10);
    const currentBottomEdge = object.position.y + object.size.height;
    const moveByY = canvasHeight - currentBottomEdge;

    if (moveByY > 0) {
      setMoveBy({ x: 0, y: moveByY });

      const updatedObjects = {
        bgFiles: bgFiles.map(bg => bg.id === selectedContainer ? { ...bg, position: { ...bg.position, y: bg.position.y + moveByY } } : bg),
        modelFiles: modelFiles.map(model => model.id === selectedContainer ? { ...model, position: { ...model.position, y: model.position.y + moveByY } } : model),
        textBoxes: textBoxes.map(textBox => textBox.id === selectedContainer ? { ...textBox, position: { ...textBox.position, y: textBox.position.y + moveByY } } : textBox),
        shapes: shapes.map(shape => shape.id === selectedContainer ? { ...shape, position: { ...shape.position, y: shape.position.y + moveByY } } : shape),
      };

      updateState(updatedObjects);

      setTimeout(() => setMoveBy({ x: 0, y: 0 }), 200);
    }
  }
};


const handleShapeUpdate = (updatedShape) => {
  setShapes((prevShapes) =>
    prevShapes.map((shape) =>
      shape.id === updatedShape.id ? { ...shape, ...updatedShape } : shape
    )
  );

  setLayers((prevLayers) =>
    prevLayers.map((layer) =>
      layer.id === updatedShape.id ? { ...layer, ...updatedShape } : layer
    )
  );
};


  const handleBgFileUpdate = (updatedBg) => {
  setBgFiles((prevBgFiles) =>
  prevBgFiles.map((bg) =>
    bg.id === updatedBg.id ? { ...bg, ...updatedBg } : bg
  )
  );

  setLayers((prevLayers) =>
    prevLayers.map((layer) =>
      layer.id === updatedBg.id ? { ...layer, ...updatedBg } : layer
    )
  );
};

const handleModelUpdate = (updatedModel) => {
  setModelFiles((prevModelFiles) =>
  prevModelFiles.map((model) =>
    model.id === updatedModel.id ? { ...model, ...updatedModel } : model
  )
  );

  setLayers((prevLayers) =>
    prevLayers.map((layer) =>
      layer.id === updatedModel.id ? { ...layer, ...updatedModel } : layer
    )
  );
};

const handleTextBoxUpdate = (updatedTextBox) => {
  setTextBoxes((prevTextBoxes) =>
  prevTextBoxes.map((textBox) =>
    textBox.id === updatedTextBox.id ? { ...textBox, ...updatedTextBox } : textBox
  )
  );
  setLayers((prevLayers) =>
    prevLayers.map((layer) =>
      layer.id === updatedTextBox.id ? { ...layer, ...updatedTextBox } : layer
    )
  );
};

  const updateGuidelines = useCallback(() => {
  const canvasWidth = parseInt(canvasSize.width, 10);
  const canvasHeight = parseInt(canvasSize.height, 10);
  setVerticalGuidelines([0, canvasWidth / 2, canvasWidth]);
  setHorizontalGuidelines([0, canvasHeight / 2, canvasHeight]);
  }, [canvasSize]);

  useEffect(() => {
  updateGuidelines();
  }, [canvasSize, updateGuidelines]);

  const getElementGuidelines = () => {
  return moveableRefs.current.map(ref => ref.current).filter(ref => ref !== null);
  
};

  const getCanvasCenterPosition = () => {
  const canvasWidth = parseInt(canvasSize.width, 10);
  const canvasHeight = parseInt(canvasSize.height, 10);
  return {
    x: canvasWidth / 2,
    y: canvasHeight / 2,
  };
  };

  const handleBgUpload = (event) => {
    const files = Array.from(event.target.files);
    files.forEach((file, index) => {
      const img = new Image();
      const fileName = file.name.replace(/\.[^/.]+$/, ""); // Strip extension
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const newBgFile = {
          id: `bg-${Date.now()}-${index}`,
          name: `Image ${index + 1}`,
          fileName,
          url: img.src,
          position: { x: 100, y: 100 },
          size: { width: img.width * 0.5, height: img.height * 0.5 },
          zIndex: getHighestZIndex(layers) + 1,
          rotation: 0,
          exposure: 1,
          isVisible: true,
          flip: { scaleX: 1, scaleY: 1 }
        };
  
        const updatedBgFiles = bgFiles.map((file) => ({ ...file, isSelected: false }));
        const newBgFiles = [...updatedBgFiles, { ...newBgFile, isSelected: true }];
        const newLayers = [newBgFile, ...layers];
  
        // Use updateState to add the new background image and update layers
        updateState({
          bgFiles: newBgFiles,
          modelFiles,
          textBoxes,
          shapes,
          layers: newLayers
        });
  
        // Other state updates that don't affect history-tracked state
        setSelectedContainer(newBgFile.id);
        handleObjectClick(newBgFile.id);
        captureDivRef.current?.focus();
      };
      img.onerror = () => console.error("Failed to load image.");
    });
    bgInputRef.current.value = null;
  };
  
  const handleAddToCanvas = (imageUrl, url) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const newImage = {
        id: `ai-image-${Date.now()}`,
        name: `AI Generated Image`,
        url: img.src,
        position: { x: 100, y: 100 },
        size: { width: img.width * 0.5, height: img.height * 0.5 },
        zIndex: getHighestZIndex(layers) + 1,
        rotation: 0,
        exposure: 1,
        isVisible: true,
        flip: { scaleX: 1, scaleY: 1 }
      };
  
      const updatedBgFiles = bgFiles.map((file) => ({ ...file, isSelected: false }));
      const newBgFiles = [...updatedBgFiles, { ...newImage, isSelected: true }];
      const newLayers = [newImage, ...layers];
  
      // Use updateState to save the new state
      updateState({
        bgFiles: newBgFiles,
        modelFiles,
        textBoxes,
        shapes,
        layers: newLayers
      });
  
      // Other state updates
      setSelectedContainer(newImage.id);
      handleObjectClick(newImage.id);
      captureDivRef.current?.focus();
    };
    img.onerror = () => console.error("Failed to load AI-generated image.");
  };
    
  const handleImageButtonClick = (image) => {
    const img = new Image();
    img.src = image.fileSrc;
    img.onload = () => {
      const fileName = image.name.replace(/\.[^/.]+$/, ""); // Strip extension
      const initialPosition = getCanvasCenterPosition();
      const newBgFile = {
        id: `bg-${Date.now()}`,
        name: `Image ${fileName}`, // Use cleaned file name
        url: img.src,
        position: initialPosition,
        size: { width: img.width * 0.5, height: img.height * 0.5 },
        rotation: 0,
        exposure: 1,
        zIndex: getHighestZIndex(layers) + 1,
        isVisible: true, // Make sure the image is visible when created
      };
  
      setBgFiles((prev) => {
        const updatedFiles = prev.map((file) => ({ ...file, isSelected: false }));
        return [
          ...updatedFiles,
          { ...newBgFile, isSelected: true }, // Select the newly added image
        ];
      });
  
      setSelectedContainer(newBgFile.id); // Set the new image as selected
      setLayers((prev) => [newBgFile, ...prev]); // Add the new image to layers
    };
  };
  

  const handleModelUpload = (event) => {
    const files = Array.from(event.target.files);
    const newModelFiles = files.map((file, index) => {
      const fileName = file.name.replace(/\.[^/.]+$/, "");
      return {
        id: `model-${Date.now()}-${index}`,
        name: `Model ${index + 1}`,
        url: URL.createObjectURL(file),
        fileName,
        position: { x: 100, y: 100 },
        size: { width: 300, height: 300 },
        rotation: 0,
        exposure: 4,
        lightColor: "#ffffff",
        zIndex: getHighestZIndex(layers) + 1,
        isVisible: true,
        flip: { scaleX: 1, scaleY: 1 },
        scale: 1
      };
    });
  
    const updatedModelFiles = modelFiles.map((file) => ({ ...file, isSelected: false }));
    const newModelFilesWithSelection = [
      ...updatedModelFiles,
      ...newModelFiles.map((model, index) => ({ ...model, isSelected: index === 0 }))
    ];
    const newLayers = [...newModelFiles, ...layers];
  
    // Use updateState to add the new 3D models and update layers
    updateState({
      bgFiles,
      modelFiles: newModelFilesWithSelection,
      textBoxes,
      shapes,
      layers: newLayers
    });
  
    // Other state updates
    setSelectedContainer(newModelFiles[0].id);
    handleObjectClick(newModelFiles[0].id);
    modelInputRef.current.value = null;
    captureDivRef.current?.focus();
  };
  
  const handleClearCanvas = () => {
    // Use updateState to clear all elements on the canvas and allow it to be undoable
    updateState({
      bgFiles: [],
      modelFiles: [],
      textBoxes: [],
      shapes: [],
      layers: []
    });
  
    // Additional state updates that don't require undo/redo handling
    setSelectedContainer(null);
  };
  
  const deselectAllObjects = () => {
    setSelectedContainer(null);
    setBgFiles((prev) =>
      prev.map((file) => ({ ...file, isSelected: false }))
    );
    setModelFiles((prev) =>
      prev.map((file) => ({ ...file, isSelected: false }))
    );
    setTextBoxes((prev) =>
      prev.map((box) => ({ ...box, isSelected: false, isEditing: false }))
    );
    setShapes((prev) =>
      prev.map((shape) => ({ ...shape, isSelected: false }))
    );
  };

  function calculateBoundingBoxDimensions(width, height, rotationAngle) {
    const radians = (rotationAngle * Math.PI) / 180;
    const rotatedWidth =
      Math.abs(width * Math.cos(radians)) +
      Math.abs(height * Math.sin(radians));
    const rotatedHeight =
      Math.abs(width * Math.sin(radians)) +
      Math.abs(height * Math.cos(radians));
    return { rotatedWidth, rotatedHeight };
    }

    const handleExport = async (fileName, exportFormat, transparentBg) => {
      const captureDiv = captureDivRef.current;
      if (captureDiv) {
        const boundingRect = captureDiv.getBoundingClientRect();
    
        // Get the specified canvas size from the sidebar inputs
        const exportWidth = parseInt(canvasSize.width, 10);
        const exportHeight = parseInt(canvasSize.height, 10);
    
        // Get the current zoom level (scale)
        const currentZoom = zoom || 1;
    
        // Get computed transform to calculate rotation angle
        const transformStyle = window.getComputedStyle(captureDiv).transform;
        const matrix = new DOMMatrix(transformStyle);
    
        // Ensure the matrix is valid
        if (!matrix) {
          console.error("Matrix transformation not defined");
          return;
        }
    
        // Calculate rotation angle in radians
        const rotationAngle = Math.atan2(matrix.b, matrix.a);
    
        // Calculate the rotated width and height using utility function
        const { rotatedWidth, rotatedHeight } = calculateBoundingBoxDimensions(
          exportWidth, // Use the specified canvas size for width
          exportHeight, // Use the specified canvas size for height
          rotationAngle * (180 / Math.PI) // Convert radians to degrees for the utility function
        );
    
        // Use a higher pixel density for better quality (e.g., 2x or custom value)
        const pixelRatio = 2;
    
        // Create offscreen canvas with increased pixel density
        const offscreenCanvas = document.createElement("canvas");
        const ctx = offscreenCanvas.getContext("2d");
        offscreenCanvas.width = rotatedWidth * pixelRatio * window.devicePixelRatio;
        offscreenCanvas.height = rotatedHeight * pixelRatio * window.devicePixelRatio;
        
    
        // Set image smoothing for higher quality
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
    
        // Save original background color and apply transparency if needed
        const originalBackgroundColor = captureDiv.style.backgroundColor;
        if (transparentBg) {
          captureDiv.style.backgroundColor = "transparent";
        }
    
        // **Apply text enhancements to the captureDiv before rendering**
        const originalTextRendering = captureDiv.style.textRendering;
        const originalFontSmoothing = captureDiv.style.webkitFontSmoothing;
        await document.fonts.ready; // Wait for all fonts to be ready before rendering
        captureDiv.style.textRendering = 'optimizeLegibility';
        captureDiv.style.webkitFontSmoothing = 'antialiased';
    
        // Introduce a delay to ensure all rendering is complete
        await new Promise((resolve) => setTimeout(resolve, 200)); // 200ms delay
    
        // Adjust for zoom and pixel ratio: apply scaling
        ctx.scale((1 / currentZoom) * pixelRatio, (1 / currentZoom) * pixelRatio);
    
        // Rotate the canvas context around the center of the offscreen canvas
        ctx.translate(rotatedWidth / 2, rotatedHeight / 2);
        ctx.rotate(rotationAngle);
        ctx.translate(-exportWidth / 2, -exportHeight / 2);
    
        // Render the div onto the offscreen canvas using html2canvas
        const canvas = await html2canvas(captureDiv, {
          canvas: offscreenCanvas,
          backgroundColor: transparentBg ? null : originalBackgroundColor,
          useCORS: true,
          width: exportWidth,  // Match the specified canvas size
          height: exportHeight // Match the specified canvas size
        });
    
        // Restore the original text rendering styles
        captureDiv.style.textRendering = originalTextRendering;
        captureDiv.style.webkitFontSmoothing = originalFontSmoothing;
    
        // Create a temporary canvas for resizing to the original dimensions
        const finalCanvas = document.createElement("canvas");
        finalCanvas.width = exportWidth;
        finalCanvas.height = exportHeight;
        const finalCtx = finalCanvas.getContext("2d");
    
        // Draw the high-resolution image onto the final canvas at original dimensions
        finalCtx.drawImage(offscreenCanvas, 0, 0, exportWidth, exportHeight);
    
        // Create a download link
        const link = document.createElement("a");
        link.href = finalCanvas.toDataURL(`image/${exportFormat}`, 1.0);
        link.download = `${fileName}.${exportFormat}`;
        link.click();
    
        // Restore original background color
        captureDiv.style.backgroundColor = originalBackgroundColor;
      }
    };

    const handleExportClick = async () => {
      // Deselect all objects before exporting
      deselectAllObjects();
    
      // Delay to ensure state updates are applied
      await new Promise((resolve) => setTimeout(resolve, 100));
    
      // Call the export function
      handleExport(fileName, exportFormat, transparentBg);
    };


    const handleDeleteSelected = (event) => {
      if (event.key === "Delete" || event.key === "Backspace") {
        const isEditing =
          textBoxes.some((textBox) => textBox.isEditing) ||
          document.activeElement.tagName === "INPUT" ||
          document.activeElement.tagName === "TEXTAREA" ||
          sidebarRef1.current.contains(document.activeElement) ||
          sidebarRef2.current.contains(document.activeElement) ||
          sidebarRef3.current.contains(document.activeElement) ||
          sidebarRef4.current.contains(document.activeElement) ||
          sidebarRef5.current.contains(document.activeElement) ||
          sidebarRef6.current.contains(document.activeElement);
    
        if (!isEditing && selectedContainer) {
          const updatedBgFiles = bgFiles.filter((file) => file.id !== selectedContainer);
          const updatedModelFiles = modelFiles.filter((file) => file.id !== selectedContainer);
          const updatedTextBoxes = textBoxes.filter((box) => box.id !== selectedContainer);
          const updatedShapes = shapes.filter((shape) => shape.id !== selectedContainer);
          const updatedLayers = layers.filter((layer) => layer.id !== selectedContainer);
    
          // Use updateState to record the deletion in the undo stack
          updateState({
            bgFiles: updatedBgFiles,
            modelFiles: updatedModelFiles,
            textBoxes: updatedTextBoxes,
            shapes: updatedShapes,
            layers: updatedLayers,
          });
    
          // Reset the selection as part of the deletion
          setSelectedContainer(null);
        }
      }
    };
    

  useEffect(() => {
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const canvas = canvasRef.current;

  if (canvas) {
    canvas.addEventListener("contextmenu", handleContextMenu);
  }

  window.addEventListener("keydown", handleDeleteSelected);

  return () => {
    if (canvas) {
    canvas.removeEventListener("contextmenu", handleContextMenu);
    }
    window.removeEventListener("keydown", handleDeleteSelected);
  };
  }, [selectedContainer, textBoxes]);

 // Modified handleCanvasSizeChange function with linked resizing
const handleCanvasSizeChange = (e) => {
  const { name, value } = e.target;
  if (!value.match(/^\d+$/)) return; // Ensure the value is numeric
  
  // Convert the string value to an integer for calculation
  const intValue = parseInt(value, 10);
  
  // Get the current canvas dimensions without "px"
  const currentWidth = parseInt(canvasSize.width.replace("px", ""), 10);
  const currentHeight = parseInt(canvasSize.height.replace("px", ""), 10);
  
  // Calculate the aspect ratio based on current dimensions
  const aspectRatio = currentHeight / currentWidth;
  
  let newWidth = currentWidth;
  let newHeight = currentHeight;

  // Adjust the opposite dimension if linked
  if (isLinked) {
    if (name === "width") {
      newWidth = intValue;
      newHeight = Math.round(newWidth * aspectRatio);
    } else if (name === "height") {
      newHeight = intValue;
      newWidth = Math.round(newHeight / aspectRatio);
    }
  } else {
    // If not linked, only update the dimension that was changed
    if (name === "width") newWidth = intValue;
    if (name === "height") newHeight = intValue;
  }

  // Update the canvas size with px suffix
  setCanvasSize({
    width: `${newWidth}px`,
    height: `${newHeight}px`
  });
};
  

  const handleInputFocus = (e) => {
  e.target.select();
  };

  const handleZoomChange = (e) => {
  setZoom(e.target.value);
  };

  const toggleSidebar = (sidebarId) => {
    // Always set the clicked sidebar as active, without closing it
    setActiveSidebar(sidebarId);
    openSidebar(sidebarId); // Highlight the active button
  };
  const clearActiveState = () => {
  const toggleButtons = document.querySelectorAll(".navbar .toggle-button");
  toggleButtons.forEach((button) => button.classList.remove("active"));
  };

  const openSidebar = (sidebarId) => {
  setActiveSidebar(sidebarId);
  clearActiveState();
  const toggleButton = document.querySelector(`.toggle-button-${sidebarId}`);
  if (toggleButton) {
    toggleButton.classList.add("active");
  }
  };

 const addTextBox = () => {
  const initialPosition = getCanvasCenterPosition();
  const newTextBox = {
    id: `text-${Date.now()}`,
    name: `Text Box`,
    position: initialPosition,
    size: { width: 200, height: 100 },
    rotation: 0,
    content: "Insert text here",
    isSelected: true,
    isEditing: false,
    fontSize: "25px",
    fontFamily: "Poppins",
    fontWeight: "Normal",
    letterSpacing: 0, // Default to no letter spacing
    lineHeight: 100,  // Default to 100% (1.0)
    textAlign: 'left', // Default to left-aligned
    verticalAlign: 'top',
    color: `rgba(0, 0, 0, ${textOpacity})`,
    zIndex: getHighestZIndex(layers) + 1,
    isVisible: true,
    flip: { scaleX: 1, scaleY: 1 }
  };

  const updatedTextBoxes = textBoxes.map((textBox) => ({ ...textBox, isSelected: false }));
  const newTextBoxes = [...updatedTextBoxes, newTextBox];
  const newLayers = [newTextBox, ...layers];

  // Use updateState to add the new text box and update layers
  updateState({
    bgFiles,
    modelFiles,
    textBoxes: newTextBoxes,
    shapes,
    layers: newLayers
  });

  // Other state updates
  setSelectedContainer(newTextBox.id);
  handleObjectClick(newTextBox.id);
  setFontSize(newTextBox.fontSize);
  setFontFamily(newTextBox.fontFamily);
  setFontColor(newTextBox.color);
  setSelectedFontWeight(newTextBox.fontWeight); 
  setTextOpacity(1);
  openSidebar(4);
  captureDivRef.current?.focus();
};


  const handleTextChange = (id, newText) => {
  setTextBoxes((prev) => {
    const newTextBoxes = [...prev];
    const index = newTextBoxes.findIndex((box) => box.id === id);
    newTextBoxes[index].content = newText;
    return newTextBoxes;
  });
  };

  const handleFontWeightChange = (id, weight, saveOnEnd = false) => {
    // Track if history has already been updated
    let historyUpdated = false;
  
    // Update font weight
    setTextBoxes((prevTextBoxes) => {
      const newTextBoxes = [...prevTextBoxes];
      const index = newTextBoxes.findIndex((box) => box.id === id);
  
      if (index !== -1) {
        const textBox = newTextBoxes[index];
        const fontFamily = textBox.fontFamily;
  
        // Update font weight
        textBox.fontWeight = weight;
  
        // Update Fabric.js canvas
        const canvasElement = document.getElementById(`fabricCanvas-${textBox.id}`);
        const fabricCanvas = canvasElement?.fabricCanvas;
        const fabricTextBox = fabricCanvas?.getObjects('textbox')[0];
  
        if (fabricTextBox) {
          fabricTextBox.set({
            fontWeight: weight,
            fontFamily,
          });
          fabricCanvas.renderAll();
        }
  
        // Save to history only if it's the final action
        if (saveOnEnd && !historyUpdated) {
          updateState({ textBoxes: newTextBoxes });
          historyUpdated = true; // Ensure history is updated only once
        }
      }
  
      return newTextBoxes;
    });
  };
  
  
  
  
  
  const handleFontColorChange = (textboxId, colorValue, opacity, saveOnEnd = false) => {
    const updatedTextBoxes = textBoxes.map((textbox) =>
      textbox.id === textboxId
        ? { ...textbox, color: colorValue, opacity }
        : textbox
    );
  
    setTextBoxes(updatedTextBoxes);
  
    // Update the Fabric.js textbox directly
    const canvasElement = document.getElementById(`fabricCanvas-${textboxId}`);
    if (canvasElement) {
      const fabricCanvas = canvasElement.fabricCanvas;
      const fabricTextBox = fabricCanvas?.getObjects("textbox")[0];
  
      if (fabricTextBox) {
        fabricTextBox.set("fill", colorValue);
        fabricTextBox.set("opacity", opacity);
        fabricCanvas.renderAll();
      }
    }
  
    // Save to history only if this is the final change
    if (saveOnEnd) {
      updateState({
        textBoxes: updatedTextBoxes,
        shapes, // Retain current state for other entities
        layers,
        bgFiles,
        modelFiles,
      });
    }
  };
  

  

  const addShape = (shapeType) => {
  const initialPosition = getCanvasCenterPosition();
  const newShape = {
    id: `shape-${Date.now()}`,
    name: `${shapeType.charAt(0).toUpperCase() + shapeType.slice(1)}`,
    type: shapeType,
    position: initialPosition,
    rotation: 0,
    size: { width: 100, height: 100 },
    color: '#CBCBCB',
    opacity: 1,
    isSelected: true,
    zIndex: getHighestZIndex(layers) + 1,
    isVisible: true,
    flip: { scaleX: 1, scaleY: 1 }
  };

  // Update the shape state with history handling
  const updatedShapes = shapes.map((shape) => ({ ...shape, isSelected: false }));
  const newShapes = [...updatedShapes, newShape];
  const newLayers = [newShape, ...layers];

  // Save the new state to history, replacing previous setShapes and setLayers calls
  updateState({
    bgFiles,
    modelFiles,
    textBoxes,
    shapes: newShapes,
    layers: newLayers
  });

  // Other state updates that don't affect shapes array directly
  setSelectedContainer(newShape.id);
  handleObjectClick(newShape.id);
  setShapeColor(newShape.color);
  setShapeOpacity(newShape.opacity);
  openSidebar(5);
  captureDivRef.current?.focus();
};


  const handleObjectClick = (id) => {
  setSelectedContainer(id);
  setBgFiles((prev) =>
    prev.map((file) => ({
    ...file,
    isSelected: file.id === id,
    })),
  );
  setModelFiles((prev) =>
    prev.map((file) => ({
    ...file,
    isSelected: file.id === id,
    })),
  );
  setTextBoxes((prev) => {
    const updatedTextBoxes = prev.map((box) => ({
    ...box,
    isSelected: box.id === id,
    isEditing: false,
    }));
    const selectedTextBox = updatedTextBoxes.find((box) => box.id === id);
    if (selectedTextBox) {
    setFontSize(selectedTextBox.fontSize);
    setFontFamily(selectedTextBox.fontFamily);
    setFontColor(selectedTextBox.color);
    setSelectedFontWeight(selectedTextBox.fontWeight || "Normal");
    openSidebar(4);
    
    }
    return updatedTextBoxes;
  });
  setShapes((prev) => {
    const updatedShapes = prev.map((shape) => ({
    ...shape,
    isSelected: shape.id === id,
    }));
    const selectedShape = updatedShapes.find((shape) => shape.id === id);
    if (selectedShape) {
    setShapeColor(selectedShape.color);
    setShapeOpacity(selectedShape.opacity);
    openSidebar(5);
    }
    return updatedShapes;
  });

  const selectedBgFile = bgFiles.find((file) => file.id === id);
  const selectedModelFile = modelFiles.find((file) => file.id === id);

  if (selectedBgFile) {
    openSidebar(2);
  } else if (selectedModelFile) {
    openSidebar(3);
  }
  };

  useEffect(() => {
    const selectedTextBox = textBoxes.find((box) => box.id === selectedContainer);
    if (selectedTextBox) {
      setSelectedFontWeight(selectedTextBox.fontWeight || "Normal");
    }
  }, [selectedContainer, textBoxes]);
  
  
  const handleCanvasClick = (e) => {
    // Get the canvas area
    const canvasArea = document.querySelector('.three-canvas-area');
    const canvasBackground = document.querySelector('.three-canvas-area-background');
  
    // Check if an input field is currently focused
    const activeElement = document.activeElement;
    if (activeElement && activeElement.tagName === 'INPUT' || 'SELECT') {
      // Let the input blur event trigger first
      activeElement.blur();
      setTimeout(() => {
        // After blur has occurred, handle the canvas click
        handleCanvasClickLogic(e, canvasArea, canvasBackground);
      }, 0);
      return;
    }
  
    // Proceed with canvas click logic immediately if no input is focused
    handleCanvasClickLogic(e, canvasArea, canvasBackground);
  };
  
  // Extracted core canvas click logic
  const handleCanvasClickLogic = (e, canvasArea, canvasBackground) => {
    // Calculate click position relative to the canvas
    const canvasRect = canvasArea.getBoundingClientRect();
    const clickX = e.clientX - canvasRect.left;
    const clickY = e.clientY - canvasRect.top;
  
    // Ensure deselection only occurs if the click is inside the three-canvas-area or three-canvas-area-background
    if (canvasArea.contains(e.target) || canvasBackground.contains(e.target)) {
      // Check if the click is on an empty space (outside any object)
      if (isClickOnEmptySpace(clickX, clickY)) {
        // Deselect all objects if the click is on an empty space
        setSelectedContainer(null);
        setBgFiles((prev) =>
          prev.map((file) => ({ ...file, isSelected: false }))
        );
        setModelFiles((prev) =>
          prev.map((file) => ({ ...file, isSelected: false }))
        );
        setTextBoxes((prev) =>
          prev.map((box) => ({ ...box, isSelected: false, isEditing: false }))
        );
        setShapes((prev) =>
          prev.map((shape) => ({ ...shape, isSelected: false }))
        );
      }
    }
  };
  
  // Helper function to determine if the click is on empty space
  const isClickOnEmptySpace = (x, y) => {
    const allObjects = [...bgFiles, ...modelFiles, ...textBoxes, ...shapes];
  
    // Loop through all objects to see if the click falls inside any of them
    for (const object of allObjects) {
      const { position, size } = object;
      if (
        x >= position.x &&
        x <= position.x + size.width &&
        y >= position.y &&
        y <= position.y + size.height
      ) {
        return false; // The click is on an object
      }
    }
    return true; // The click is on empty space
  };

  const handleFontSizeChange = (e, saveOnEnd = false) => {
    if (selectedContainer) {
      setTextBoxes((prev) => {
        const newTextBoxes = [...prev];
        const index = newTextBoxes.findIndex((box) => box.id === selectedContainer);
  
        if (index !== -1) {
          newTextBoxes[index].fontSize = `${e.target.value}px`;
  
          // Update the Fabric.js textbox
          const canvasElement = document.getElementById(`fabricCanvas-${newTextBoxes[index].id}`);
          const fabricCanvas = canvasElement.fabricCanvas;
          const fabricTextBox = fabricCanvas.getObjects('textbox')[0];
  
          if (fabricTextBox) {
            fabricTextBox.set({ fontSize: parseInt(e.target.value, 10) });
            fabricCanvas.renderAll(); // Redraw the canvas
          }
        }
  
        return newTextBoxes;
      });
  
      setFontSize(`${e.target.value}px`);
  
      // Save the updated state to history if this is the final change
      if (saveOnEnd) {
        updateState({ textBoxes }); // Save the updated textBoxes to history
      }
    }
  };
  

  // Function to change font family in Fabric.js Textbox
  const handleFontFamilyChange = (e, saveOnEnd = false) => {
    if (selectedContainer) {
      setTextBoxes((prev) => {
        const newTextBoxes = [...prev];
        const index = newTextBoxes.findIndex((box) => box.id === selectedContainer);
  
        if (index !== -1) {
          // Update the font family in the text box
          newTextBoxes[index].fontFamily = e.target.value;
  
          // Update the font family of the Fabric.js textbox
          const canvasElement = document.getElementById(`fabricCanvas-${newTextBoxes[index].id}`);
          if (canvasElement) {
            const fabricCanvas = canvasElement.fabricCanvas;
            const fabricTextBox = fabricCanvas.getObjects('textbox')[0];
  
            if (fabricTextBox) {
              fabricTextBox.set({ fontFamily: e.target.value });
              fabricCanvas.renderAll(); // Redraw the canvas
            }
          }
        }
  
        return newTextBoxes;
      });
  
      setFontFamily(e.target.value);
  
      // Save to history if this is the final change (e.g., user stops interacting)
      if (saveOnEnd) {
        updateState({ textBoxes }); // Save the updated textBoxes to history
      }
    }
  };
  
const handleShapeColorChange = (shapeId, colorValue, opacity, saveOnEnd = false) => {
  const updatedShapes = shapes.map((shape) =>
    shape.id === shapeId
      ? { ...shape, color: colorValue, opacity }
      : shape
  );

  setShapes(updatedShapes);

  // Save to history only if this is the final change
  if (saveOnEnd) {
    updateState({
      shapes: updatedShapes,
      layers, // Layers are not updated, so retain the current state
      bgFiles,
      modelFiles,
      textBoxes,
    });
  }
};



const handleExposureChange = (e, id, saveOnBlur = false) => {
  const newExposure = parseFloat(e.target.value);

  // Update model files immediately
  const updatedModelFiles = modelFiles.map((model) =>
    model.id === id ? { ...model, exposure: newExposure } : model
  );
  setModelFiles(updatedModelFiles);

  // Save to history only on mouse up
  if (e.type === "mouseup") {
    updateState({
      modelFiles: updatedModelFiles,
    });
  }

  if (saveOnBlur) {
    updateState({
      modelFiles: updatedModelFiles,
    });
  }
};

const handleImageExposureChange = (value, id, saveOnEnd = false) => {
  const newExposure = parseFloat(value);

  // Update the exposure for real-time feedback
  const updatedBgFiles = bgFiles.map((file) =>
    file.id === id ? { ...file, exposure: newExposure } : file
  );

  setBgFiles(updatedBgFiles);

  // Save the state to history only onMouseUp or onBlur
  if (saveOnEnd) {
    updateState({
      bgFiles: updatedBgFiles,
    });
  }
};



// Function to parse RGB string like 'rgb(113, 39, 39)' to an object
const parseRGBString = (rgbaString) => {
  const result = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/.exec(rgbaString);
  return result
    ? {
        r: parseInt(result[1], 10),
        g: parseInt(result[2], 10),
        b: parseInt(result[3], 10),
        a: result[4] !== undefined ? parseFloat(result[4]) : 1, // Default alpha to 1
      }
    : null;
};


// Handle color change (includes opacity handling now)
const handleCanvasBackgroundColorChange = (color, saveOnEnd = false) => {
  const newColor = color?.rgb
    ? {
        r: color.rgb.r,
        g: color.rgb.g,
        b: color.rgb.b,
        a: color.rgb.a || 1, // Default opacity to 1 if not provided
      }
    : parseRGBString(color);

  if (newColor) {
    setCanvasBackgroundColor(newColor);

    // Save to history only if this is the final change (onMouseUp or onBlur)
    if (saveOnEnd) {
      
      updateState({ canvasBackgroundColor: newColor });
    }
  } else {
    console.error("Invalid color format:", color);
  }
};



  const handleSelectLayer = (id) => {
  setSelectedContainer(id);
  handleObjectClick(id);
  };

  const handleRenameLayer = (id, newName) => {
    // Update the layers array
    const updatedLayers = layers.map((layer) =>
      layer.id === id ? { ...layer, name: newName } : layer
    );
  
    // Update the shapes array if the layer is a shape
    const updatedShapes = shapes.map((shape) =>
      shape.id === id ? { ...shape, name: newName } : shape
    );
  
    // Update other arrays (e.g., bgFiles, modelFiles, textBoxes) if needed
    const updatedBgFiles = bgFiles.map((file) =>
      file.id === id ? { ...file, name: newName } : file
    );
  
    const updatedModelFiles = modelFiles.map((model) =>
      model.id === id ? { ...model, name: newName } : model
    );
  
    const updatedTextBoxes = textBoxes.map((textBox) =>
      textBox.id === id ? { ...textBox, name: newName } : textBox
    );
  
    // Replace the entire arrays to trigger a re-render
    setLayers([...updatedLayers]);
    setShapes([...updatedShapes]);
    setBgFiles([...updatedBgFiles]);
    setModelFiles([...updatedModelFiles]);
    setTextBoxes([...updatedTextBoxes]);
  
    // Save the updated state to history
    updateState({
      layers: updatedLayers,
      bgFiles: updatedBgFiles,
      modelFiles: updatedModelFiles,
      textBoxes: updatedTextBoxes,
      shapes: updatedShapes,
    });
  };
  

  
  
  const handleMoveLayer = (fromIndex, toIndex, saveToHistory) => {
    const updatedLayers = [...layers];
    const [movedLayer] = updatedLayers.splice(fromIndex, 1);
    updatedLayers.splice(toIndex, 0, movedLayer);
  
    // Reorder zIndex for all affected items
    const reorderedBgFiles = reorderZIndex(bgFiles, updatedLayers);
    const reorderedModelFiles = reorderZIndex(modelFiles, updatedLayers);
    const reorderedTextBoxes = reorderZIndex(textBoxes, updatedLayers);
    const reorderedShapes = reorderZIndex(shapes, updatedLayers);
  
    // Update state
    setLayers(updatedLayers);
    setBgFiles(reorderedBgFiles);
    setModelFiles(reorderedModelFiles);
    setTextBoxes(reorderedTextBoxes);
    setShapes(reorderedShapes);
  
    // Save the updated state to history only if requested
    if (saveToHistory) {
      updateState({
        layers: updatedLayers,
        bgFiles: reorderedBgFiles,
        modelFiles: reorderedModelFiles,
        textBoxes: reorderedTextBoxes,
        shapes: reorderedShapes,
      });
    }
  };
  
  
  const handleLetterSpacingChange = (id, value, saveOnEnd = false) => {
    const percentageValue = value / 100; // Convert percentage to a scale factor (0% to 100%)
    let historyUpdated = false; // Prevent duplicate updates
  
    setTextBoxes((prev) => {
      const newTextBoxes = [...prev];
      const index = newTextBoxes.findIndex((box) => box.id === id);
      newTextBoxes[index].letterSpacing = value; // Keep the percentage in state
  
      // Update the Fabric.js textbox
      const canvasElement = document.getElementById(`fabricCanvas-${newTextBoxes[index].id}`);
      const fabricCanvas = canvasElement.fabricCanvas;
      const fabricTextBox = fabricCanvas.getObjects('textbox')[0];
  
      fabricTextBox.set({
        charSpacing: percentageValue * 1000, // Fabric.js uses thousandths of an em
      });
  
      fabricCanvas.renderAll(); // Redraw the canvas
  
      if (saveOnEnd && !historyUpdated) {
        updateState({ textBoxes: newTextBoxes }); // Save to history
        historyUpdated = true;
      }
  
      return newTextBoxes;
    });
  };
  
  const handleLineHeightChange = (id, value, saveOnEnd = false) => {
    const lineHeightValue = value / 100; // Convert percentage to lineHeight ratio (100% = 1)
    let historyUpdated = false;
  
    setTextBoxes((prev) => {
      const newTextBoxes = [...prev];
      const index = newTextBoxes.findIndex((box) => box.id === id);
      newTextBoxes[index].lineHeight = value; // Keep the percentage in state
  
      // Update the Fabric.js textbox
      const canvasElement = document.getElementById(`fabricCanvas-${newTextBoxes[index].id}`);
      const fabricCanvas = canvasElement.fabricCanvas;
      const fabricTextBox = fabricCanvas.getObjects('textbox')[0];
  
      fabricTextBox.set({
        lineHeight: lineHeightValue, // Set the lineHeight ratio in Fabric.js
      });
  
      fabricCanvas.renderAll(); // Redraw the canvas
  
      if (saveOnEnd && !historyUpdated) {
        updateState({ textBoxes: newTextBoxes });
        historyUpdated = true;
      }
  
      return newTextBoxes;
    });
  };
  

  const handleTextAlignChange = (id, align, saveOnEnd = false) => {
    let historyUpdated = false;
  
    setTextBoxes((prev) => {
      const newTextBoxes = [...prev];
      const index = newTextBoxes.findIndex((box) => box.id === id);
      newTextBoxes[index].textAlign = align;
  
      // Update the Fabric.js textbox
      const canvasElement = document.getElementById(`fabricCanvas-${newTextBoxes[index].id}`);
      const fabricCanvas = canvasElement.fabricCanvas;
      const fabricTextBox = fabricCanvas.getObjects('textbox')[0];
  
      fabricTextBox.set({
        textAlign: align,
      });
  
      fabricCanvas.renderAll(); // Redraw the canvas
  
      if (saveOnEnd && !historyUpdated) {
        updateState({ textBoxes: newTextBoxes });
        historyUpdated = true;
      }
  
      return newTextBoxes;
    });
  };
  

  const handleVerticalAlignChange = (id, verticalAlign, saveOnEnd = false) => {
    let historyUpdated = false;
  
    setTextBoxes((prev) => {
      const newTextBoxes = [...prev];
      const index = newTextBoxes.findIndex((box) => box.id === id);
      const selectedTextBox = newTextBoxes[index];
  
      // Update the Fabric.js canvas textbox
      const canvasElement = document.getElementById(`fabricCanvas-${selectedTextBox.id}`);
      const fabricCanvas = canvasElement.fabricCanvas;
      const fabricTextBox = fabricCanvas.getObjects('textbox')[0];
  
      const textHeight = fabricTextBox.height;
      const containerHeight = fabricCanvas.height; // Assuming the container is the Fabric.js canvas height
  
      let newTop;
      switch (verticalAlign) {
        case 'top':
          fabricTextBox.set({ originY: 'top' });
          newTop = 0;
          break;
        case 'middle':
          fabricTextBox.set({ originY: 'top' });
          newTop = (containerHeight - textHeight) / 2;
          break;
        case 'bottom':
          fabricTextBox.set({ originY: 'bottom' });
          newTop = containerHeight;
          break;
        default:
          return;
      }
  
      fabricTextBox.set({ top: newTop });
  
      fabricCanvas.renderAll();
  
      newTextBoxes[index].verticalAlign = verticalAlign;
  
      if (saveOnEnd && !historyUpdated) {
        updateState({ textBoxes: newTextBoxes });
        historyUpdated = true;
      }
  
      return newTextBoxes;
    });
  };
  
  const handleToggleVisibility = (id, isVisible) => {
    const updateVisibility = (items, setItems) => {
      const updatedItems = items.map((item) => {
        if (item.id === id) {
          const latestPosition = item.position;
          const latestSize = item.size;
          const latestRotation = item.rotation;
  
          // Ensure these values have defaults if undefined
          const latestLetterSpacing = item.letterSpacing ?? 0;
          const latestLineHeight = item.lineHeight ?? 100;
          const latestTextAlign = item.textAlign ?? "left";
          const latestVerticalAlign = item.verticalAlign ?? "top";
  
          const is3DModel = modelFiles.some((model) => model.id === item.id);
  
          const updatedItem = {
            ...item,
            isVisible,
            position:
              isVisible && item.hiddenState
                ? item.hiddenState.position
                : latestPosition,
            size:
              isVisible && item.hiddenState
                ? item.hiddenState.size
                : latestSize,
            rotation:
              isVisible && item.hiddenState
                ? item.hiddenState.rotation
                : latestRotation,
            letterSpacing:
              isVisible && item.hiddenState
                ? item.hiddenState.letterSpacing
                : latestLetterSpacing,
            lineHeight:
              isVisible && item.hiddenState
                ? item.hiddenState.lineHeight
                : latestLineHeight,
            textAlign:
              isVisible && item.hiddenState
                ? item.hiddenState.textAlign
                : latestTextAlign,
            verticalAlign:
              isVisible && item.hiddenState
                ? item.hiddenState.verticalAlign
                : latestVerticalAlign,
            hiddenState: !isVisible
              ? {
                  position: latestPosition,
                  size: latestSize,
                  rotation: latestRotation,
                  letterSpacing: latestLetterSpacing,
                  lineHeight: latestLineHeight,
                  textAlign: latestTextAlign,
                  verticalAlign: latestVerticalAlign,
                  ...(is3DModel && item.controlsState
                    ? { controlsState: item.controlsState }
                    : {}),
                }
              : item.hiddenState,
            ...(is3DModel && isVisible && item.hiddenState?.controlsState
              ? { controlsState: item.hiddenState.controlsState }
              : {}),
          };
  
          return updatedItem;
        }
        return item;
      });
  
      setItems(updatedItems);
      return updatedItems;
    };
  
    // Update visibility for all the relevant objects
    const updatedBgFiles = updateVisibility(bgFiles, setBgFiles);
    const updatedModelFiles = updateVisibility(modelFiles, setModelFiles);
    const updatedTextBoxes = updateVisibility(textBoxes, setTextBoxes);
    const updatedShapes = updateVisibility(shapes, setShapes);
  
    // Synchronize layers array
    const updatedLayers = layers.map((layer) => {
      const correspondingItem =
        updatedBgFiles.find((item) => item.id === layer.id) ||
        updatedModelFiles.find((item) => item.id === layer.id) ||
        updatedTextBoxes.find((item) => item.id === layer.id) ||
        updatedShapes.find((item) => item.id === layer.id);
  
      if (correspondingItem) {
        return {
          ...layer,
          isVisible: correspondingItem.isVisible, // Sync isVisible state
        };
      }
      return layer;
    });
  
    // Update the layers array
    setLayers(updatedLayers);
  
    // Save the updated state to history
    updateState({
      layers: updatedLayers,
      bgFiles: updatedBgFiles,
      modelFiles: updatedModelFiles,
      textBoxes: updatedTextBoxes,
      shapes: updatedShapes,
    });
  };
  
  

  const handleDuplicateSelected = () => {
    const selectedObject = getSelectedObject();
  
    if (selectedObject) {
      const duplicate = {
        ...selectedObject,
        id: `${selectedObject.id}-duplicate-${Date.now()}`, // Unique ID for the duplicate
        position: {
          x: selectedObject.position.x + 100, // Offset to avoid exact overlap
          y: selectedObject.position.y + 20,
        },
        isSelected: false, // Deselect initially
      };
  
      // Determine the type of object to add the duplicate to the correct category
      let newBgFiles = bgFiles;
      let newModelFiles = modelFiles;
      let newTextBoxes = textBoxes;
      let newShapes = shapes;
      let newLayers = [duplicate, ...layers]; // Add duplicate to layers
  
      if (bgFiles.some((bg) => bg.id === selectedContainer)) {
        newBgFiles = [...bgFiles, duplicate];
      } else if (modelFiles.some((model) => model.id === selectedContainer)) {
        alert("This functionality is not applicable for the selected object");
        return;
      } else if (textBoxes.some((textBox) => textBox.id === selectedContainer)) {
        newTextBoxes = [...textBoxes, duplicate];
      } else if (shapes.some((shape) => shape.id === selectedContainer)) {
        newShapes = [...shapes, duplicate];
      }
  
      // Update state and history in one function call
      updateState({
        bgFiles: newBgFiles,
        modelFiles: newModelFiles,
        textBoxes: newTextBoxes,
        shapes: newShapes,
        layers: newLayers
      });
    }
  };
  
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="three-canvas-container" onMouseDown={handleCanvasClick}>
      <div className={`header-container ${isSliderShifted ? 'shifted' : ''}`}>
        <button onClick={handleUndo}>
          <img src="undo.png"></img>
        </button>
        <button onClick={handleRedo}>
          <img src="redo.png"></img>
        </button> 
        <button>
          <img src="duplicate.png" onClick={handleDuplicateSelected}></img>
        </button>
        <div className="visual-assistant-button" onClick={handleImageAiBarClick}>
          <ImageAiBar className="visual-assistant" handleAddToCanvas={handleAddToCanvas} />
        </div>
      </div>
      <div className="navbar">
        <div className="logo-container">
        <img src="vl-eye.png" alt="Logo" />
        </div>
        <div className="button-container">
        <button
          className="toggle-button toggle-button-1"
          onClick={() => toggleSidebar(1)}
        />
        </div>
        <div className="button-container">
        <button
          className="toggle-button toggle-button-2"
          onClick={() => toggleSidebar(2)}
        />
        </div>
        <div className="button-container">
        <button
          className="toggle-button toggle-button-3"
          onClick={() => toggleSidebar(3)}
        />
        </div>
        <div className="button-container">
        <button
          className="toggle-button toggle-button-4"
          onClick={() => toggleSidebar(4)}
        />
        </div>
        <div className="button-container">
        <button
          className="toggle-button toggle-button-5"
          onClick={() => toggleSidebar(5)}
        />
        </div>
        <div className="button-container">
        <button
          className="toggle-button toggle-button-6"
          onClick={() => toggleSidebar(6)}
        />
        </div>
      </div>
  
  
      <div
        ref={sidebarRef1}
        className={`three-canvas-sidebar ${activeSidebar === 1 ? "expanded" : ""}`}
        style={{ display: activeSidebar === 1 ? "block" : "none" }}
      >
      <div className="sidebar-content-wrapper">
          <h1>Canvas Settings</h1>
          <hr></hr>
        <div className="size-input-container-shapes">
          <div className="size-input-container-shapes-w">
            <label>W</label>
              <input
                type="text"
                name="width"
                value={canvasSize.width.replace("px", "")}
                onChange={handleCanvasSizeChange}
                onFocus={handleInputFocus}
                step={1}
              />
                <span class="suffix">px</span>
            </div>
            <div className="size-input-container-shapes-h">
              <label>
              H
              </label>
              <input
                type="text"

                name="height"
                value={canvasSize.height.replace("px", "")}
                onChange={handleCanvasSizeChange}
                onFocus={handleInputFocus}
                step={1}
              />
              <span class="suffix">px</span>
            </div>
            <button onClick={toggleLinkDimensions} style={isLinked ? LINKED_BUTTON_STYLE.active : LINKED_BUTTON_STYLE.inactive}>
                <img src="link.png" alt="Link Dimensions" title="Link Dimensions"/>
              </button>
        </div>
        <div className="buttons-container" id="clear-button">
          <button onClick={handleClearCanvas}>Clear Canvas</button>
        </div>
        <hr />
         {/* Background color picker */}
         <h2>Appearance</h2>
         <div>       
          <ColorPickerBar
          color={`rgba(${canvasBackgroundColor.r}, ${canvasBackgroundColor.g}, ${canvasBackgroundColor.b}, ${canvasBackgroundColor.a})`}
          onColorChange={(newColor, saveOnEnd) => handleCanvasBackgroundColorChange(newColor, saveOnEnd)}
/>
              
            </div>
            <hr />
            <h2>Export Options</h2>
           
    <div className="modal-content">  
      <div className="export-inputs">
        <input
        type="text"
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
        onFocus={handleInputFocus}
        />
        <select
        value={exportFormat}
        onChange={(e) => setExportFormat(e.target.value)}
        >
        <option value="png">PNG</option>
        <option value="jpeg">JPEG</option>
        <option value="webp">WebP</option>
        </select>
      </div>
 
    <div className="export-checkbox">
      <input
      type="checkbox"
      checked={transparentBg}
      onChange={(e) => setTransparentBg(e.target.checked)}
      />
      <label>Transparent Background</label>
    </div>
    <div className="export-button">
      <button onClick={handleExportClick}>
        Export Canvas
      </button>
    </div>
    </div>
     
      </div>
      </div>
  
      <div
      ref={sidebarRef2}
      className={`three-canvas-sidebar-2 ${activeSidebar === 2 ? "expanded" : ""}`}
      style={{ display: activeSidebar === 2 ? "block" : "none" }}
    >
      <div className="sidebar-content-wrapper">
      <h1>Images</h1>
      <hr></hr>
      <div className="buttons-container">
        <button onClick={() => bgInputRef.current.click()}>
          Upload Image
        </button>
        <input
          ref={bgInputRef}
          id="bgUpload"
          type="file"
          accept="image/*"
          multiple
          style={{ display: "none" }}
          onChange={handleBgUpload}
        />
              </div>
              {/* <ImageAiBar handleAddToCanvas={handleAddToCanvas}/> */}
        <button
          id="shutterstock-button"
          onClick={() =>
          window.open("https://www.shutterstock.com/", "_blank")
          }
        ></button>

        
        
        <hr />
        <h2>Position</h2>
        <div className="position-customization">
      <div className="move-buttons-shapes">
        <div className="move-buttons-horizontal-shapes">
          <button onClick={handleMoveObjectLeft} disabled={!selectedContainer} id="left-align">
            <img src="left-align.png"/>
          </button>
          <button onClick={handleMoveObjectCenterX} disabled={!selectedContainer} id="center-align">
            <img src="v-center-align.png"/>
          </button>
          <button onClick={handleMoveObjectRight} disabled={!selectedContainer} id="right-align">
            <img src="right-align.png"/>
          </button>
        </div>
        <div className="move-buttons-vertical-shapes">
          <button onClick={handleMoveObjectTop} disabled={!selectedContainer} id="left-align">
            <img src="top-align.png"/>
          </button>
          <button onClick={handleMoveObjectCenterY} disabled={!selectedContainer} id="center-align">
            <img src="h-center-align.png"/>
          </button>
          <button onClick={handleMoveObjectBottom} disabled={!selectedContainer} id="right-align">
            <img src="bottom-align.png"/>
          </button>
        </div>
      </div>
      <div className="rotation-customization-container-shapes" >
        <div className="rotation-customization-input-shapes">
          <label>
            <img src="angle.png" alt=""/>
          </label>
            <input
              type="number"
              disabled={!bgFiles.some((bg) => bg.id === selectedContainer)}
              onFocus={handleInputFocus}
              value={
                bgFiles.find((bg) => bg.id === selectedContainer)
                  ? Math.round(bgFiles.find((bg) => bg.id === selectedContainer).rotation)
                  : 0
              }
        onChange={(e) =>
          handleRotateObject(parseInt(e.target.value, 10) - bgFiles.find((bg) => bg.id === selectedContainer).rotation)
        }
        onBlur={(e) =>
          handleRotateObject(parseInt(e.target.value, 10) - bgFiles.find((bg) => bg.id === selectedContainer).rotation, true)
        }
            />
          
        </div>
          <div className="rotation-customization-buttons-shapes">
            <button id="left-align" onClick={rotateBy90Degrees}>
              <img src="rotate.png" />
            </button>
            <button id="center-align" onClick={handleHorizontalFlip} disabled={!selectedContainer}>
              <img src="v-flip.png"/>
            </button>
            <button id="right-align" onClick={handleVerticalFlip} disabled={!selectedContainer}>
              <img src="h-flip.png"/>
            </button>
          </div>
        </div>
        </div>
        <hr />
  <h2>Layout</h2>
      <div className="size-input-container-shapes">
      <div className="size-input-container-shapes-w">
        <label>W</label>
          <input
            type="number"
            disabled={!bgFiles.some((bg) => bg.id === selectedContainer)}
            value={
              bgFiles.find((bg) => bg.id === selectedContainer)
                ? Math.round(bgFiles.find((bg) => bg.id === selectedContainer).size.width)
                : ''
            }
          onChange={(e) => handleResizeObjectWidth(parseInt(e.target.value, 10) - bgFiles.find((bg) => bg.id === selectedContainer).size.width)}
          step="1"  // Ensures only whole numbers are allowed
          onFocus={handleInputFocus}
          />
            <span class="suffix">px</span>
            
        
        </div>
        <div className="size-input-container-shapes-h">
        <label>H</label>
          <input
            type="number"
            disabled={!bgFiles.some((bg) => bg.id === selectedContainer)}
            value={
              bgFiles.find((bg) => bg.id === selectedContainer)
                ? Math.round(bgFiles.find((bg) => bg.id === selectedContainer).size.height)
                : ''
            }
          onChange={(e) => handleResizeObjectHeight(parseInt(e.target.value, 10) - bgFiles.find((bg) => bg.id === selectedContainer).size.height)}
          step="1"  // Ensures only whole numbers are allowed
          onFocus={handleInputFocus}
          />
            <span class="suffix">px</span>
      
        </div>
        <button onClick={toggleLinkDimensions} style={isLinked ? LINKED_BUTTON_STYLE.active : LINKED_BUTTON_STYLE.inactive}>
          <img src="link.png" alt="Link Dimensions" title="Link Dimensions"/>
        </button>

      </div>
      <hr />
      <h2>Appearence</h2>
        {/* Color Picker */}
        <div className="exposure-sliders">
        {bgFiles.map((bg, index) => (
        <ImageExposureSlider
          key={bg.id}
          bg={bg}
          handleImageExposureChange={handleImageExposureChange}
          showHeading={index === 0} // Show heading only for the first image
        />
      ))}
      </div>
     <hr />
     {/* <h2>Shadow Library</h2>
      <div id="scrollable-container">
      {imageButtons.map((image) => (
        <button
        key={image.id}
        id="image-button"
        onClick={() => handleImageButtonClick(image)}
        style={{ backgroundImage: `url(${image.thumbnailSrc})` }}
        ></button>
      ))}
      </div> */}
     
    </div>
    </div>
      <div
      ref={sidebarRef3}
      className={`three-canvas-sidebar-3 ${activeSidebar === 3 ? "expanded" : ""}`}
      style={{ display: activeSidebar === 3 ? "block" : "none" }}
    >
      <div className="sidebar-content-wrapper">
        <h1>Interactive Render</h1> 
        <hr></hr> 
        <div className="buttons-container">
        <button onClick={() => modelInputRef.current.click()}>
          Upload Render .glb
        </button>
        <input
          ref={modelInputRef}
          id="modelUpload"
          type="file"
          accept=".glb"
          multiple
          style={{ display: "none" }}
          onChange={handleModelUpload}
        />
        </div>
     
        <hr />
        <h2>Position</h2>
        <div className="position-customization">
          <div className="move-buttons-shapes">
            <div className="move-buttons-horizontal-shapes">
              <button onClick={handleMoveObjectLeft} disabled={!selectedContainer} id="left-align">
                <img src="left-align.png"/>
              </button>
              <button onClick={handleMoveObjectCenterX} disabled={!selectedContainer} id="center-align">
                <img src="v-center-align.png"/>
              </button>
              <button onClick={handleMoveObjectRight} disabled={!selectedContainer} id="right-align">
                <img src="right-align.png"/>
              </button>
            </div>
            <div className="move-buttons-vertical-shapes">
              <button onClick={handleMoveObjectTop} disabled={!selectedContainer} id="left-align">
                <img src="top-align.png"/>
              </button>
              <button onClick={handleMoveObjectCenterY} disabled={!selectedContainer} id="center-align">
                <img src="h-center-align.png"/>
              </button>
              <button onClick={handleMoveObjectBottom} disabled={!selectedContainer} id="right-align">
                <img src="bottom-align.png"/>
              </button>
            </div>
          </div>
      
      <div className="rotation-customization-container-shapes">
        <div className="rotation-customization-input-shapes">
          <label>
            <img src="angle.png" alt=""/>
          </label>
            <input
                type="number"
                onFocus={handleInputFocus}
                disabled={!modelFiles.some((model) => model.id === selectedContainer)}
                value={
                  modelFiles.find((model) => model.id === selectedContainer)
                    ? Math.round(modelFiles.find((model) => model.id === selectedContainer).rotation)
                    : 0
                }
                onChange={(e) =>
                  handleRotateObject(parseInt(e.target.value, 10) - modelFiles.find((model) => model.id === selectedContainer).rotation)
                }
                onBlur={(e) =>
                  handleRotateObject(parseInt(e.target.value, 10) - modelFiles.find((model) => model.id === selectedContainer).rotation, true)
                }
            />
        
        </div>
          <div className="rotation-customization-buttons-shapes">
          <button id="left-align" onClick={rotateBy90Degrees}>
              <img src="rotate.png" />
            </button>
            <button id="center-align" onClick={handleHorizontalFlip} disabled={!selectedContainer}>
              <img src="v-flip.png"/>
            </button>
            <button id="right-align" onClick={handleVerticalFlip} disabled={!selectedContainer}>
              <img src="h-flip.png"/>
            </button>
          </div>
          </div>
          </div>
        <hr />        
        <h2>Layout</h2>
      <div className="size-input-container-shapes">
      <div className="size-input-container-shapes-h">
        <label>W</label>
          <input
            type="number"
            disabled={!modelFiles.some((model) => model.id === selectedContainer)}
            onFocus={handleInputFocus}
            value={
              modelFiles.find((model) => model.id === selectedContainer)
                ? Math.round(modelFiles.find((model) => model.id === selectedContainer).size.width)
                : ''
            }
        onChange={(e) =>
          handleResizeObjectWidth(parseInt(e.target.value, 10) - modelFiles.find((model) => model.id === selectedContainer).size.width)
        }
          />
            <span class="suffix">px</span>
          </div>
          <div className="size-input-container-shapes-w">       
        <label>H</label>
          <input
            type="number"
            onFocus={handleInputFocus}
            disabled={!modelFiles.some((model) => model.id === selectedContainer)}
            value={
              modelFiles.find((model) => model.id === selectedContainer)
                ? Math.round(modelFiles.find((model) => model.id === selectedContainer).size.height)
                : ''
            }
        onChange={(e) =>
          handleResizeObjectHeight(parseInt(e.target.value, 10) - modelFiles.find((model) => model.id === selectedContainer).size.height)
        }
          />
            <span class="suffix">px</span>
            </div>

            <button onClick={toggleLinkDimensions} style={isLinked ? LINKED_BUTTON_STYLE.active : LINKED_BUTTON_STYLE.inactive}>
          <img src="link.png" alt="Link Dimensions" title="Link Dimensions"/>
        </button>
      </div>
      <hr />
      <h2>Appearence</h2>
        {/* Color Picker */}
     
        <div className="exposure-sliders">
        {modelFiles.map((model, index) => (
          <ExposureSlider key={model.id} model={model} handleExposureChange={handleExposureChange} showHeading={index === 0} />
        ))}
      </div>
      
       
    </div>
    </div>
    
      <div
      ref={sidebarRef4}
      className={`three-canvas-sidebar-4 ${activeSidebar === 4 ? "expanded" : ""}`}
      style={{ display: activeSidebar === 4 ? "block" : "none" }}
    >
      <div className="sidebar-content-wrapper">
      <h1>Text Editor</h1>
      <hr />
        <div className="buttons-container">
          <button onClick={addTextBox}>Add Text Box</button>
        </div>
        <hr />
              
          <h2>Typography</h2>
          <div className="font-customization-container">
        <div className="font-picker">
          <FontPickerBar
          fontFamily={fontFamily}
          onFontChange={handleFontFamilyChange}
          fontFamilies={fontFamilies} 
          />
        </div>
          {/* Font Size and Font Weight/Style in a single row */}
          <div className="font-size-font-weight">
                <input
                  type="number"
                  value={parseInt(fontSize)}
                  disabled={!textBoxes.some((box) => box.id === selectedContainer)}
                  onFocus={handleInputFocus}
                  onChange={(e) => handleFontSizeChange(e, false)} // Immediate change
                  onBlur={(e) => handleFontSizeChange(e, true)} // Final change for history
                  style={{ width: "100%" }}
                />
                  <select
                    value={selectedFontWeight || "Normal"} // Fallback to "Normal"
                    onChange={(e) => handleFontWeightChange(selectedContainer, e.target.value, false)} // Immediate change
                    onBlur={() => handleFontWeightChange(selectedContainer, selectedFontWeight, true)} // Final change for history
                    disabled={!textBoxes.some((box) => box.id === selectedContainer)}
                  >
                    {fontWeights.map((weight) => (
                      <option key={weight.value} value={weight.value}>
                        {weight.label}
                      </option>
                    ))}
                  </select>
          </div>
          <div className="letter-spacing-container">
  <div className="h-letter-spacing">
    <label>
      <img src="h-letter-spacing.png" alt="Letter Spacing" />
      </label>
    
    <input
      type="number"
      value={textBoxes.find((box) => box.id === selectedContainer)?.letterSpacing || 0}
      onChange={(e) =>
        handleLetterSpacingChange(selectedContainer, e.target.value, false) // Real-time update
      }
      onBlur={(e) =>
        handleLetterSpacingChange(selectedContainer, e.target.value, true) // Save to history
      }
      id="input-field"
      min="0"
      max="100"
      onFocus={handleInputFocus}
      disabled={!textBoxes.some((box) => box.id === selectedContainer)}
    />
    
  </div>

  <div className="v-letter-spacing">
    <label>
      <img src="v-letter-spacing.png" alt="Line Height" />
    </label>
    <input
      type="number"
      value={textBoxes.find((box) => box.id === selectedContainer)?.lineHeight || 100}
      onChange={(e) =>
        handleLineHeightChange(selectedContainer, parseInt(e.target.value, 10), false) // Real-time update
      }
      onBlur={(e) =>
        handleLineHeightChange(selectedContainer, parseInt(e.target.value, 10), true) // Save to history
      }
      id="input-field"
      min="0"
      max="200"
      onFocus={handleInputFocus}
      disabled={!textBoxes.some((box) => box.id === selectedContainer)}
    />
    
  </div>
</div>


              {/* Text Alignment (Left, Center, Right) */}
              <div className="letter-align-buttons-container">
                <button
                  className={`alignment-button ${textBoxes.find((box) => box.id === selectedContainer)?.textAlign === 'left' ? 'active' : ''}`}
                  onClick={() => handleTextAlignChange(selectedContainer, 'left', true)} // Save to history immediately
                  disabled={!textBoxes.some((box) => box.id === selectedContainer)}
                >
                  <img
                    src="left-align-letter.png"
                    alt="Align Left"
        
                  />
                </button>
                <button
                  className={`alignment-button ${textBoxes.find((box) => box.id === selectedContainer)?.textAlign === 'center' ? 'active' : ''}`}
                  onClick={() => handleTextAlignChange(selectedContainer, 'center', true)}
                  disabled={!textBoxes.some((box) => box.id === selectedContainer)}
                >
                  <img
                    src="center-align-letter.png"
                    alt="Align Center"
                 
                  />
                </button>
                <button
                  className={`alignment-button ${textBoxes.find((box) => box.id === selectedContainer)?.textAlign === 'right' ? 'active' : ''}`}
                  onClick={() => handleTextAlignChange(selectedContainer, 'right', true)}
                  disabled={!textBoxes.some((box) => box.id === selectedContainer)}
                >
                  <img
                    src="right-align-letter.png"
                    alt="Align Right"
                    
                  />
                </button>
                  <button
                    className={`alignment-button ${textBoxes.find((box) => box.id === selectedContainer)?.verticalAlign === 'top' ? 'active' : ''}`}
                    onClick={() => handleVerticalAlignChange(selectedContainer, 'top', true)}
                    disabled={!textBoxes.some((box) => box.id === selectedContainer)}
                  >
                    <img src="top-align-letter.png" alt="Align Top"  />
                  </button>
                  <button
                    className={`alignment-button ${textBoxes.find((box) => box.id === selectedContainer)?.verticalAlign === 'middle' ? 'active' : ''}`}
                    onClick={() => handleVerticalAlignChange(selectedContainer, 'middle', true)}
                    disabled={!textBoxes.some((box) => box.id === selectedContainer)}
                  >
                    <img src="middle-align-letter.png" alt="Align Middle"  />
                  </button>
                  <button
                    className={`alignment-button ${textBoxes.find((box) => box.id === selectedContainer)?.verticalAlign === 'bottom' ? 'active' : ''}`}
                    onClick={() => handleVerticalAlignChange(selectedContainer, 'bottom', true)}
                    disabled={!textBoxes.some((box) => box.id === selectedContainer)}
                  >
                    <img src="bottom-align-letter.png" alt="Align Bottom" />
                  </button>
              </div>
              </div>

              <hr />
              <h2>Position</h2>
              <div className="position-customization">
                <div className="move-buttons-shapes">
                  <div className="move-buttons-horizontal-shapes">
                    <button onClick={handleMoveObjectLeft} disabled={!selectedContainer} id="left-align">
                      <img src="left-align.png"/>
                    </button>
                    <button onClick={handleMoveObjectCenterX} disabled={!selectedContainer} id="center-align">
                      <img src="v-center-align.png"/>
                    </button>
                    <button onClick={handleMoveObjectRight} disabled={!selectedContainer} id="right-align">
                      <img src="right-align.png"/>
                    </button>
                  </div>
                  <div className="move-buttons-vertical-shapes">
                    <button onClick={handleMoveObjectTop} disabled={!selectedContainer} id="left-align">
                      <img src="top-align.png"/>
                    </button>
                    <button onClick={handleMoveObjectCenterY} disabled={!selectedContainer} id="center-align">
                      <img src="h-center-align.png"/>
                    </button>
                    <button onClick={handleMoveObjectBottom} disabled={!selectedContainer} id="right-align">
                      <img src="bottom-align.png"/>
                    </button>
                  </div>
                </div>
                <div className="rotation-customization-container-shapes">
                  <div className="rotation-customization-input-shapes">
                    <label>
                      <img src="angle.png" alt=""/>
                    </label>
                      <input
                        type="number"
                        onFocus={handleInputFocus}
                        disabled={!textBoxes.some((textBox) => textBox.id === selectedContainer)}
                        value={
                          textBoxes.find((textBox) => textBox.id === selectedContainer)
                            ? Math.round(textBoxes.find((textBox) => textBox.id === selectedContainer).rotation)
                            : 0
                        }
                  onChange={(e) =>
                    handleRotateObject(parseInt(e.target.value, 10) - textBoxes.find((textBox) => textBox.id === selectedContainer).rotation)
                  }
                  onBlur={(e) =>
                    handleRotateObject(parseInt(e.target.value, 10) - textBoxes.find((textBox) => textBox.id === selectedContainer).rotation, true)
                  }
                      />
                  
                  </div>
                    <div className="rotation-customization-buttons-shapes">
                    <button id="left-align" onClick={rotateBy90Degrees}>
              <img src="rotate.png" />
            </button>
            <button id="center-align" onClick={handleHorizontalFlip} disabled={!selectedContainer}>
              <img src="v-flip.png"/>
            </button>
            <button id="right-align" onClick={handleVerticalFlip} disabled={!selectedContainer}>
              <img src="h-flip.png"/>
            </button>
                    </div>
                  </div>
                  </div>
        <hr />



        <div className="size-customisation-container">
        <h2>Layout</h2>
      <div className="size-input-container-shapes">
      <div className="size-input-container-shapes-w">
        <label>W</label>
          <input
           type="number"
           onFocus={handleInputFocus}
           disabled={!textBoxes.some((textBox) => textBox.id === selectedContainer)}
           value={
            textBoxes.find((textBox) => textBox.id === selectedContainer)
              ? Math.round(textBoxes.find((textBox) => textBox.id === selectedContainer).size.width)
              : ''
          }
        onChange={(e) =>
          handleResizeObjectWidth(parseInt(e.target.value, 10) - textBoxes.find((textBox) => textBox.id === selectedContainer).size.width)
        }
          />
            <span class="suffix">px</span>
           </div>
           <div className="size-input-container-shapes-h"> 
      
        <label>H</label>
          <input
            type="number"
            onFocus={handleInputFocus}
            disabled={!textBoxes.some((textBox) => textBox.id === selectedContainer)}
            value={
              textBoxes.find((textBox) => textBox.id === selectedContainer)
                ? Math.round(textBoxes.find((textBox) => textBox.id === selectedContainer).size.height)
                : ''
            }
        onChange={(e) =>
          handleResizeObjectHeight(parseInt(e.target.value, 10) - textBoxes.find((textBox) => textBox.id === selectedContainer).size.height)
            }
          />
            <span class="suffix">px</span>
            </div>
        
            <button onClick={toggleLinkDimensions} style={isLinked ? LINKED_BUTTON_STYLE.active : LINKED_BUTTON_STYLE.inactive}>
          <img src="link.png" alt="Link Dimensions" title="Link Dimensions"/>
        </button>
      </div>
      </div>
        <hr />
        <h2>Appearence</h2>       
        <ColorPickerBar
  color={fontColor} // Pass the current font color
  onColorChange={(newColor, saveOnEnd) => {
    setFontColor(newColor); 
    handleFontColorChange(selectedContainer, newColor, textOpacity, saveOnEnd);
  }}
/>

    </div>
    </div>
    <div
      ref={sidebarRef5}
      className={`three-canvas-sidebar-5 ${activeSidebar === 5 ? "expanded" : ""}`}
      style={{ display: activeSidebar === 5 ? "block" : "none" }}
    >
      <div className="sidebar-content-wrapper">
     <h1>Shape Editor</h1>
      <hr></hr>
      <div className="buttons-container-shapes">
    <button onClick={() => addShape("square")} aria-label="Add Square">
      <svg width="100%" height="100%" viewBox="0 0 24 24">
        <rect x="2" y="2" width="20" height="20" style={{ fill: "currentColor" }} />
      </svg>
    </button>
    <button onClick={() => addShape("circle")} aria-label="Add Circle">
      <svg width="24" height="24" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="10" style={{ fill: "currentColor" }} />
      </svg>
    </button>
    <button onClick={() => addShape("triangle")} aria-label="Add Triangle">
      <svg width="24" height="24" viewBox="0 0 24 24">
        <polygon points="12,2 22,22 2,22" style={{ fill: "currentColor" }} />
      </svg>
    </button>
  </div>
    
      <>
      <hr/>
      <h2>Position</h2>
      <div className="position-customization">
      <div className="move-buttons-shapes">
        <div className="move-buttons-horizontal-shapes">
          <button onClick={handleMoveObjectLeft} disabled={!selectedContainer} id="left-align">
            <img src="left-align.png"/>
          </button>
          <button onClick={handleMoveObjectCenterX} disabled={!selectedContainer} id="center-align">
            <img src="v-center-align.png"/>
          </button>
          <button onClick={handleMoveObjectRight} disabled={!selectedContainer} id="right-align">
            <img src="right-align.png"/>
          </button>
        </div>
        <div className="move-buttons-vertical-shapes">
          <button onClick={handleMoveObjectTop} disabled={!selectedContainer} id="left-align">
            <img src="top-align.png"/>
          </button>
          <button onClick={handleMoveObjectCenterY} disabled={!selectedContainer} id="center-align">
            <img src="h-center-align.png"/>
          </button>
          <button onClick={handleMoveObjectBottom} disabled={!selectedContainer} id="right-align">
            <img src="bottom-align.png"/>
          </button>
        </div>
      </div>
      <div className="rotation-customization-container-shapes">
        <div className="rotation-customization-input-shapes">
          <label>
            <img src="angle.png" alt=""/>
          </label>
            <input
              type="number"
              disabled={!shapes.some((shape) => shape.id === selectedContainer)}
              onFocus={handleInputFocus}
              value={
                shapes.find((shape) => shape.id === selectedContainer)
                  ? Math.round(shapes.find((shape) => shape.id === selectedContainer).rotation)
                  : 0
              }
              onChange={(e) =>
                handleRotateObject(parseInt(e.target.value, 10) - shapes.find((shape) => shape.id === selectedContainer).rotation)
              }
              onBlur={(e) =>
                handleRotateObject(
                  parseInt(e.target.value, 10) - shapes.find((shape) => shape.id === selectedContainer).rotation,
                  true // Save the final value to history
                )
              }
            />
        
        </div>
          <div className="rotation-customization-buttons-shapes">
          <button id="left-align" onClick={rotateBy90Degrees}>
              <img src="rotate.png" />
            </button>
            <button id="center-align" onClick={handleHorizontalFlip} disabled={!selectedContainer}>
              <img src="v-flip.png"/>
            </button>
            <button id="right-align" onClick={handleVerticalFlip} disabled={!selectedContainer}>
              <img src="h-flip.png"/>
            </button>
          </div>
        </div>
        </div>
       
      <hr></hr>
      <h2>Layout</h2>
      <div className="size-input-container-shapes">
      <div className="size-input-container-shapes-w">
        <label>W</label>
          <input
            type="number"
            disabled={!shapes.some((shape) => shape.id === selectedContainer)}
            onFocus={handleInputFocus}
            value={
              shapes.find((shape) => shape.id === selectedContainer)
                ? Math.round(shapes.find((shape) => shape.id === selectedContainer).size.width)
                : ''
            }
            onChange={(e) =>
              handleResizeObjectWidth(parseInt(e.target.value, 10) - shapes.find((shape) => shape.id === selectedContainer).size.width)
            }
            onBlur={(e) =>
              handleResizeObjectWidth(
                parseInt(e.target.value, 10) - shapes.find((shape) => shape.id === selectedContainer).size.width,
                true // Save to history on blur
              )
            }
          />
            <span class="suffix">px</span>
          </div>
          <div className="size-input-container-shapes-h">
     
        <label>H</label>
          <input
            type="number"
            disabled={!shapes.some((shape) => shape.id === selectedContainer)}
            onFocus={handleInputFocus}
            value={
              shapes.find((shape) => shape.id === selectedContainer)
                ? Math.round(shapes.find((shape) => shape.id === selectedContainer).size.height)
                : ''
            }
            onChange={(e) =>
              handleResizeObjectHeight(parseInt(e.target.value, 10) - shapes.find((shape) => shape.id === selectedContainer).size.height)
            }
            onBlur={(e) =>
              handleResizeObjectHeight(
                parseInt(e.target.value, 10) - shapes.find((shape) => shape.id === selectedContainer).size.height,
                true // Save to history on blur
              )
            }
          />
           <span class="suffix">px</span>
        
        </div>
       
        <button onClick={toggleLinkDimensions} style={isLinked ? LINKED_BUTTON_STYLE.active : LINKED_BUTTON_STYLE.inactive}>
          <img src="link.png" alt="Link Dimensions" title="Link Dimensions"/>
        </button>
      </div>
      <hr/>
      <h2>Appearance</h2>
    
      <ColorPickerBar
      color={shapeColor} // Pass the current shape color
      onColorChange={(newColor, saveOnEnd) => {
        setShapeColor(newColor); 
        handleShapeColorChange(selectedContainer, newColor, shapeOpacity, saveOnEnd)
      }}
      />


     
  
      </>
      </div>
      </div>
    <div
      ref={sidebarRef6}
      className={`three-canvas-sidebar-6 ${activeSidebar === 6 ? "expanded" : ""}`}
      style={{ display: activeSidebar === 6 ? "block" : "none" }}
    >
      <div className="sidebar-content-wrapper">

      <h1>Layers Panel</h1>
      <hr></hr>
      <LayerPanel
      layers={layers}
      onSelectLayer={handleSelectLayer}
      onMoveLayer={handleMoveLayer}
      onRenameLayer={handleRenameLayer}
      onToggleVisibility={handleToggleVisibility}
      />
    </div>
    </div>

    <div
      className="three-canvas-area-background"
      onMouseDown={handleCanvasClick}
    >
      <div
      ref={captureDivRef}
      key={canvasKey}
      tabIndex={-1}  // Makes the div focusable
      className={`three-canvas-area ${isSliderShifted ? 'shifted' : ''}`}
      style={{
        width: canvasSize.width,
        height: canvasSize.height,
        transform: `scale(${zoom})`,
        backgroundColor: `rgba(${canvasBackgroundColor.r}, ${canvasBackgroundColor.g}, ${canvasBackgroundColor.b}, ${canvasBackgroundColor.a})`,  // Apply RGBA color
      }}
      >
      {/* Render layers in the correct z-index order */}
      {reorderLayersByZIndex(layers).map((layer, index) => {
        // Render based on object type
        if (bgFiles.some((bg) => bg.id === layer.id)) {
        const bg = bgFiles.find((file) => file.id === layer.id);
        if (!bg.isVisible) return null;
        const refIndex = index;
        // Ensure the ref is initialized
        if (!moveableRefs.current[refIndex]) {
          moveableRefs.current[refIndex] = React.createRef();
        }
    
        return (
          <MoveableContainer
          key={bg.id}
          ref={moveableRefs.current[refIndex]}
          verticalGuidelines={verticalGuidelines}
          horizontalGuidelines={horizontalGuidelines}
          elementGuidelines={getElementGuidelines()}
          position={bg.position}
          size={bg.size}
          rotation={bg.rotation}
          isSelected={bg.isSelected}
          onClick={() => handleObjectClick(bg.id)}
          zIndex={bg.zIndex}
          onUpdate={(updatedState) => handleBgFileUpdate({ id: bg.id, ...updatedState })}
          moveBy={selectedContainer === bg.id ? moveBy : { x: 0, y: 0 }}
          resizeBy={selectedContainer === bg.id ? resizeBy : { width: 0, height: 0 }}
          rotateBy={selectedContainer === bg.id ? rotateBy : 0 }
          flip={bg.flip} // Pass the flip state as props
          onTransformationChange={(type, updatedProperties) =>
            handleTransformationChange(type, updatedProperties)
          }
          onArrowKeyNudge={updateObjectAndLayerPositions} // Pass the function as a prop
          >
          <img
            src={bg.url}
            alt={`background-${bg.id}`}
            style={{
            width: "100%",
            height: "100%",
            filter: `brightness(${bg.exposure})`, // Apply the exposure

            }}
          />
          </MoveableContainer>
        );
        }

        if (modelFiles.some((model) => model.id === layer.id)) {
        const model = modelFiles.find((file) => file.id === layer.id);
        if (!model.isVisible) return null;
        const refIndex = bgFiles.length + index;
        // Ensure the ref is initialized
        if (!moveableRefs.current[refIndex]) {
          moveableRefs.current[refIndex] = React.createRef();
        }
    
        return (
          <MoveableContainer
          key={model.id}
          ref={moveableRefs.current[refIndex]}
          verticalGuidelines={verticalGuidelines}
          horizontalGuidelines={horizontalGuidelines}
          elementGuidelines={getElementGuidelines()}
          position={model.position}
          size={model.size}
          rotation={model.rotation}
          isSelected={model.isSelected}
          onClick={() => handleObjectClick(model.id)}
          zIndex={model.zIndex}
          onUpdate={(updatedState) => handleModelUpdate({ id: model.id, ...updatedState })}
          moveBy={selectedContainer === model.id ? moveBy : { x: 0, y: 0 }}
          resizeBy={selectedContainer === model.id ? resizeBy : { width: 0, height: 0 }}
          rotateBy={selectedContainer === model.id ? rotateBy : 0 }
          flip={model.flip} // Pass the flip state as props
          onTransformationChange={(type, updatedProperties) =>
            handleTransformationChange(type, updatedProperties)
          }
          onArrowKeyNudge={updateObjectAndLayerPositions} // Pass the function as a prop
          >
            
          <Canvas
            ref={canvasRef}
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: `${model.size.width}px`,
              height: `${model.size.height}px`,
              transform: `scale(${1 / zoom})`,
            }}
            gl={{ preserveDrawingBuffer: true,
              antialias: true, // Enable anti-aliasing for smoother edges
              multisampling: 8, // Enable 8x MSAA
              physicallyCorrectLights: true,
              precision: 'highp', // Use high precision for rendering
              powerPreference: 'high-performance',
              // logarithmicDepthBuffer: true,
              }}
            //  camera={{ fov: 5, aspect: model.size.width / model.size.height }}
             pixelRatio={window.devicePixelRatio * 2} // Limits pixel ratio for better performance
             shadows // Enable shadows if needed
             toneMapping={THREE.ACESFilmicToneMapping}
             outputColorSpace={THREE.LinearSRGBColorSpace}
             
          >     
            <GLBViewerContent
            fileUrl={model.url}
            scale={zoom}
            exposure={model.exposure}
            lightColor={model.lightColor}
            controlsState={model.controlsState}
            setControlsState={(newControlsState) =>
              handleModelUpdate({ id: model.id, controlsState: newControlsState }) // Update only this model's controls state
            }
            />
          </Canvas>
          </MoveableContainer>
        );
        }

        if (textBoxes.some((textBox) => textBox.id === layer.id)) {
        const textBox = textBoxes.find((box) => box.id === layer.id);
        if (!textBox.isVisible) return null;
        const refIndex = bgFiles.length + modelFiles.length + index;
        // Ensure the ref is initialized
        if (!moveableRefs.current[refIndex]) {
          moveableRefs.current[refIndex] = React.createRef();
        }
        return (
          <MoveableContainer
          key={textBox.id}
          ref={moveableRefs.current[refIndex]}
          verticalGuidelines={verticalGuidelines}
          horizontalGuidelines={horizontalGuidelines}
          elementGuidelines={getElementGuidelines()}
          position={textBox.position}
          size={textBox.size}
          rotation={textBox.rotation}
          isSelected={textBox.isSelected}
          onClick={() => handleObjectClick(textBox.id)}
          zIndex={textBox.zIndex}
          onUpdate={(updatedState) => handleTextBoxUpdate({ id: textBox.id, ...updatedState })}
          isTextbox={true}
          moveBy={selectedContainer === textBox.id ? moveBy : { x: 0, y: 0 }}
          resizeBy={selectedContainer === textBox.id ? resizeBy : { width: 0, height: 0 }}
          rotateBy={selectedContainer === textBox.id ? rotateBy : 0 }
          flip={textBox.flip} // Pass the flip state as props
          onTransformationChange={(type, updatedProperties) =>
            handleTransformationChange(type, updatedProperties)
          }
          onArrowKeyNudge={updateObjectAndLayerPositions} // Pass the function as a prop
          selectedContainer={textBox.id}
          onBlur={handleTextboxBlur} // Pass the onBlur function
          >
          <canvas
    id={`fabricCanvas-${textBox.id}`}
    style={{
      width: textBox.size.width,
      height: textBox.size.height,
      pointerEvents: "auto",
      paddingLeft: "5px",
      paddingRight: "5px",
    }}
    ref={(canvasElement) => {
      if (canvasElement && !canvasElement.fabricInitialized) {
      const fabricCanvas = new fabric.Canvas(canvasElement, {
        width: textBox.size.width,
        height: textBox.size.height,
        
     
      });
      
      fabricCanvas.selection = false; // disable group selection
       // Add a Fabric.js textbox to the canvas
       const fabricTextBox = new fabric.Textbox(textBox.content || "Insert text here", {
        left: 0,
        top: 0,
        width: textBox.size.width, // Set textbox to full canvas width
        fontSize: parseInt(textBox.fontSize, 10),
        fontFamily: textBox.fontFamily,  // Default font
        fontWeight: textBox.fontWeight,  // Use fontWeight from state
        charSpacing: (textBox.letterSpacing / 100) * 1000, // Initialize letter spacing
        lineHeight: textBox.lineHeight / 100, // Initialize line height
        textAlign: textBox.textAlign, // Initialize text alignment
        fill: textBox.color,
        selectable: false, 
        editable: true,
        splitByGrapheme: true, // Enable splitting by individual letters
        objectCaching: false, // This helps in rendering the overflow text without caching
        hasControls: false, // Enable controls for resizing
        noScaleCache: true, // Keeps the text sharp while scaling
        hasBorders: false,
        padding: 69,
      });

      const containerHeight = textBox.size.height; // Assuming the canvas height
      const textHeight = fabricTextBox.height;
      let newTop;
      switch (textBox.verticalAlign) {
        case 'top':
          newTop = 0;
          fabricTextBox.set({ originY: 'top' });
          break;
        case 'middle':
          newTop = (containerHeight - textHeight) / 2;
          fabricTextBox.set({ originY: 'top' });
          break;
        case 'bottom':
          newTop = containerHeight - textHeight;
          fabricTextBox.set({ originY: 'bottom' });
          break;
        default:
          newTop = 0;
      }
    
      fabricTextBox.set({ top: newTop });
      

      fabricCanvas.add(fabricTextBox);
      fabricCanvas.renderAll();

      // Save a reference to the canvas to update it later
      canvasElement.fabricInitialized = true;
      canvasElement.fabricCanvas = fabricCanvas;

      if (fabricTextBox.fontWeight !== textBox.fontWeight) {
        fabricTextBox.set({ fontWeight: textBox.fontWeight || 'Normal' });
        fabricCanvas.renderAll(); // Trigger re-rendering of the canvas
      }

      // Handle fabric.js changes
      fabricCanvas.on("text:changed", (e) => {
        const updatedText = e.target.text;
        handleTextChange(textBox.id, updatedText); // Update the content in your state
      });

      // Handle size or position updates
      fabricCanvas.on("object:scaling", (e) => {
        const updatedWidth = e.target.width * e.target.scaleX;
        const updatedHeight = e.target.height * e.target.scaleY;
        handleTextBoxUpdate({
        id: textBox.id,
        size: { width: updatedWidth, height: updatedHeight },
        });
      });
      }
    }}
    />
          </MoveableContainer>
        );
        }

        if (shapes.some((shape) => shape.id === layer.id)) {
        const shape = shapes.find((s) => s.id === layer.id);
        if (!shape.isVisible) return null;
        const refIndex = bgFiles.length + modelFiles.length + textBoxes.length + index;
        // Ensure the ref is initialized
        if (!moveableRefs.current[refIndex]) {
          moveableRefs.current[refIndex] = React.createRef();
        }
    
        return (
          <MoveableContainer
          key={shape.id}
          ref={moveableRefs.current[refIndex]}
          verticalGuidelines={verticalGuidelines}
          horizontalGuidelines={horizontalGuidelines}
          elementGuidelines={getElementGuidelines()}
          position={shape.position}
          size={shape.size}
          rotation={shape.rotation}
          isSelected={shape.isSelected}
          onClick={() => handleObjectClick(shape.id)}
          onUpdate={(updatedState) => handleShapeUpdate({ id: shape.id, ...updatedState })}
          zIndex={shape.zIndex}
          moveBy={selectedContainer === shape.id ? moveBy : { x: 0, y: 0 }}
          resizeBy={selectedContainer === shape.id ? resizeBy : { width: 0, height: 0 }}
          rotateBy={selectedContainer === shape.id ? rotateBy : 0 }
          flip={shape.flip} // Pass the flip state as props
          onTransformationChange={(type, updatedProperties) =>
            handleTransformationChange(type, updatedProperties)
          }
          onArrowKeyNudge={updateObjectAndLayerPositions} // Pass the function as a prop
          >
          {shape.type === "square" && (
            <svg className="shape-square">
            <rect
              width="100%"
              height="100%"
              fill={shape.color}
              fillOpacity={shape.opacity}
            />
            </svg>
          )}
          {shape.type === "circle" && (
            <svg
            className="shape-circle"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            >
            <circle
              cx="50"
              cy="50"
              r="50"
              fill={shape.color}
              fillOpacity={shape.opacity}
            />
            </svg>
          )}
          {shape.type === "triangle" && (
            <svg
            className="shape-triangle"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            >
            <polygon
              points="50,0 0,100 100,100"
              fill={shape.color}
              fillOpacity={shape.opacity}
            />
            </svg>
          )}
          </MoveableContainer>
        );
        }
      })}
      </div>
    </div>
     <div className={`zoom-slider-container ${isSliderShifted ? 'shifted' : ''}`}>
      <input
        type="range"
        min="0.1"
        max="1.5"
        step="0.01"
        value={zoom}
        onChange={handleZoomChange}
        className="zoom-slider"
      />
      </div>
    </div>
  </DndProvider>
  );
};

const ThreeCanvasWrapper = () => (
  <ErrorBoundary>
  <ThreeCanvas />
  </ErrorBoundary>
);

export default ThreeCanvasWrapper;