import React from 'react'
import "./Login.css"

const Login = () => {
  return (  
    <>
    <img className='background-image' src='login_bg.jpg'/> 
    <div className='login-content'>
      <img src="login_img.png"/>
    <form>
      <div>
        <input
         type='email' 
         name='email' 
         id='email' 
         className='form-control' 
         placeholder='Email' />
      </div>
      <div>
        <input
         type='password' 
         name='password' 
         id='password' 
         className='form-control' 
         placeholder='Password' />
      </div>
    </form>
    </div>
    </>
  )
}

export default Login